import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales';

Vue.use(VueI18n);

const language = localStorage.getItem('language');

export default new VueI18n({
  locale: Object.prototype.hasOwnProperty.call(locales, language) ? language : 'hu',
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: locales,
});
