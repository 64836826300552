import axios from './axios';

async function listFloors() {
  const response = await axios({
    method: 'get',
    url: '/admin/floors',
  });

  return response.data;
}

async function getFloor(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/floors/${id}`,
  });

  return response.data;
}

async function getFloorHeatmap(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/floors/${id}/heatmap`,
  });

  return response.data;
}

async function createFloor(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/floors',
    data,
  });

  return response.data;
}

async function deleteFloor(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/floors/${id}`,
  });

  return response.data;
}

async function updateFloor(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/floors/${id}`,
    data,
  });

  return response.data;
}

export default {
  getFloor,
  listFloors,
  createFloor,
  deleteFloor,
  updateFloor,
  getFloorHeatmap,
};
