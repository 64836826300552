<template>
  <v-select
    :items="exhibitions"
    :item-text="`title.${language}`"
    :label="$t('exhibitionSelector.select')"
    :loading="loading"
    :return-object="true"
    @change="onChange"
  ></v-select>
</template>

<script>
export default {
  name: 'ExhibitionSelector',
  created() {
    this.$store.dispatch('exhibition/listExhibitions');
  },
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    selectorItems() {
      return [];
    },
    exhibitions() {
      return this.$store.state.exhibition.exhibitions;
    },
    loading() {
      return this.$store.state.exhibition.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>

</style>
