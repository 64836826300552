<template>
  <v-container>
    <template v-if="news">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        class="mb-4"
      >
        <v-img
          :src="news.media.prefixedUrl"
          height="200px">
        </v-img>

        <v-card-title>
          {{news.title[language]}}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('news.form.date') }}</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(news.date) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <viewer
            ref="toastuiViewer"
            v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
            :initialValue="news.content[language]"
            :options="{ usageStatistics: false }" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/news/${news.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="deleteNews(news)"
          >
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { Viewer } from '@toast-ui/vue-editor';

import i18n from '@/i18n';

export default {
  name: 'ShowNews',
  components: {
    Viewer,
  },
  created() {
    this.$store.dispatch('news/getNews', { id: this.$route.params.id });
  },
  computed: {
    news() {
      return this.$store.state.news.selectedNews;
    },
    loading() {
      return this.$store.state.news.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async deleteNews(news) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('news.deleteConfirm'),
        { color: 'red', itemName: news.title[this.language] },
      )) {
        await this.$store.dispatch('news/deleteNews', news);
        this.$router.push('/news');
      }
    },
    formatDate(date) {
      return DateTime.fromJSDate(new Date(date)).toFormat('yyyy-MM-dd');
    },
  },
  watch: {
    language() {
      this.$refs.toastuiViewer.invoke('setMarkdown', this.news.content[this.language], true);
    },
  },
};
</script>

<style scoped>

</style>
