import axios from 'axios';
import { error as errorUtil, config as getConfig } from '@/utils';

// During development we hit the local server (http://localhost:3333),
// on any other deployed environment we use the <host>/api endpoint
axios.defaults.baseURL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3333'
  : getConfig('VUE_APP_SERVER_HOST') || '/api';

axios.interceptors.request.use((request) => {
  const tokenString = localStorage.getItem('token');
  const token = JSON.parse(tokenString);
  if (token && token.token) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = `Bearer ${token.token}`;
  }

  return request;
});

axios.interceptors.response
  .use((response) => response, (error) => Promise.reject(errorUtil.getMessageFromError(error)));

export default axios;
