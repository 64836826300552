<template>
  <v-container>

    <v-form
      ref="exhibitionForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Title -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.hu"
            :label="$t('exhibitions.form.titleHU')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.en"
            :label="$t('exhibitions.form.titleEN')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.de"
            :label="$t('exhibitions.form.titleDE')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('exhibitions.form.descriptionHU')}}</h3>

          <editor ref="toastuiEditorHu"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="description.hu"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('exhibitions.form.descriptionEN')}}</h3>

          <editor ref="toastuiEditorEn"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="description.en"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('exhibitions.form.descriptionDE')}}</h3>

          <editor ref="toastuiEditorDe"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="description.de"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <v-select
        :items="typeOptions"
        v-model="type"
        :label="$t('exhibitions.form.type')"
      ></v-select>

      <v-img
        v-if="coverImagePreview && !coverImage"
        :src="coverImagePreview"
        height="200px"
      >
      </v-img>

      <v-file-input
        accept=".png, .jpg, .jpeg"
        :label="$t('exhibitions.form.coverImage')"
        :rules="isUpdate ? coverImageUpdateRules : coverImageRules"
        v-model="coverImage"
      ></v-file-input>

      <v-row v-if="type === 'temporary'">
        <v-col cols="12" sm="6">

          <v-dialog
            ref="startDateDialog"
            v-model="modal.start"
            :return-value.sync="startDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('exhibitions.form.startDate')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal.start = false"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.startDateDialog.save(startDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-col>

        <v-col cols="12" sm="6">

          <v-dialog
            ref="endDateDialog"
            v-model="modal.end"
            :return-value.sync="endDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('exhibitions.form.endDate')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="modal.end = false"
              >
                {{ $t('common.cancel') }}
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.endDateDialog.save(endDate)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>

        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';

import i18n from '@/i18n';
import Alert from '@/components/Alert.vue';

export default {
  name: 'ExhibitionForm',
  components: {
    Alert,
    Editor,
  },
  data() {
    return {
      valid: true,
      title: {
        hu: '',
        en: '',
        de: '',
      },
      description: {
        hu: '',
        en: '',
        de: '',
      },
      type: 'permanent',
      coverImage: null,
      coverImagePreview: '',
      startDate: '',
      endDate: '',
      modal: {
        start: false,
        end: false,
      },
      descriptionRules: [
        (v) => !!v || i18n.t('exhibitions.form.descriptionRule'),
      ],
      coverImageRules: [
        (v) => !!v || i18n.t('exhibitions.form.coverImageRule'),
        (v) => (!!v && v.size <= 20 * 1024 * 1024) || i18n.t('exhibitions.form.coverImageSizeRule'),
      ],
      coverImageUpdateRules: [
        (v) => {
          if (this.isUpdate) return true;
          return (!!v && v.size <= 20 * 1024 * 1024) || i18n.t('exhibitions.form.coverImageSizeRule');
        },
      ],
      isUpdate: false,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
    };
  },
  async created() {
    this.$store.dispatch('exhibition/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('exhibition/getExhibition', { id: this.$route.params.id });
      this.isUpdate = true;
      this.title = this.exhibition.title;
      this.description = this.exhibition.description;
      this.type = this.exhibition.type;
      this.coverImagePreview = this.exhibition.coverImage;
      this.startDate = this.exhibition.startDate || '';
      this.endDate = this.exhibition.endDate || '';
      this.$refs.toastuiEditorHu.invoke('setMarkdown', this.description.hu, true);
      this.$refs.toastuiEditorEn.invoke('setMarkdown', this.description.en, true);
      this.$refs.toastuiEditorDe.invoke('setMarkdown', this.description.de, true);
    }
  },
  computed: {
    typeOptions() {
      return [
        { text: i18n.t('exhibitions.form.typePermanent'), value: 'permanent' },
        { text: i18n.t('exhibitions.form.typeTemporary'), value: 'temporary' },
      ];
    },
    loading() {
      return this.$store.state.exhibition.loading;
    },
    exhibition() {
      return this.$store.state.exhibition.selectedExhibition;
    },
    error() {
      return this.$store.state.exhibition.error;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    submit(event) {
      event.preventDefault();
      if (this.$refs.exhibitionForm.validate()) {
        const {
          title, type, startDate, endDate, coverImage,
        } = this;

        if (this.isUpdate) {
          const data = {
            type,
            title,
            startDate,
            endDate,
            description: {
              hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
              en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
              de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
            },
          };
          if (coverImage) data.coverImage = coverImage;
          this.$store.dispatch('exhibition/updateExhibition', {
            id: this.exhibition.id,
            data,
          });
        } else {
          this.$store.dispatch('exhibition/createExhibition', {
            type,
            title,
            startDate,
            endDate,
            coverImage,
            description: {
              hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
              en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
              de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
            },
          });
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('exhibition/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
