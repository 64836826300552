<template>
  <v-container>

    <FloorSelector :on-change="onFloorChange"/>

    <v-expansion-panels v-if="floor" class="mb-4">
      <v-expansion-panel>

        <v-expansion-panel-header>
          {{ $t('statistics.heatmap.config') }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <div class="text-subtitle-1">{{ $t('statistics.heatmap.radius') }}</div>
          <v-slider
            max="100"
            step="5"
            min="5"
            thumb-label
            v-model="heatmapConfig.radius"
            @change="onHeatmapConfigChange"
          />

          <div class="text-subtitle-1">{{$t('statistics.heatmap.opacity')}}</div>
          <v-slider
            max="1"
            step="0.1"
            min="0"
            thumb-label
            v-model="heatmapConfig.maxOpacity"
            @change="onHeatmapConfigChange"
          />

          <div class="text-subtitle-1">{{$t('statistics.heatmap.blur')}}</div>
          <v-slider
            max="1"
            step="0.1"
            min="0"
            thumb-label
            v-model="heatmapConfig.blur"
            @change="onHeatmapConfigChange"
          />

          <div class="text-subtitle-1">{{$t('statistics.heatmap.min')}}</div>
          <v-slider
            max="100"
            step="5"
            min="0"
            thumb-label
            v-model="heatmapConfig.min"
            @change="onHeatmapConfigChange"
          />

          <div class="text-subtitle-1">{{$t('statistics.heatmap.max')}}</div>
          <v-slider
            max="100"
            step="5"
            min="0"
            thumb-label
            v-model="heatmapConfig.max"
            @change="onHeatmapConfigChange"
          />
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-img v-if="floor" :src="floor.mapMedia.prefixedUrl" ref="mapImage" @load="onLoad"></v-img>

  </v-container>
</template>

<script>
import h337 from 'heatmap.js';

import { map } from '@/utils';
import FloorSelector from '@/components/FloorSelector';

export default {
  name: 'Heatmap',
  components: {
    FloorSelector,
  },
  data() {
    return {
      imageX: 0,
      imageY: 0,
      heatmapInstance: null,
      heatmapConfig: {
        radius: 30,
        maxOpacity: 0.5,
        blur: 0.9,
        min: 0,
        max: 0,
      },
    };
  },
  computed: {
    floor() {
      return this.$store.state.floor.floorWithHeatmap;
    },
  },
  methods: {
    onHeatmapConfigChange() {
      const imgElement = this.$refs.mapImage.$el;

      // eslint-disable-next-line no-restricted-syntax
      for (const child of imgElement.children) {
        if (child.nodeName === 'CANVAS') {
          imgElement.removeChild(child);
        }
      }

      this.heatmapInstance = h337.create({
        container: imgElement,
        blur: this.heatmapConfig.blur,
        radius: this.heatmapConfig.radius,
        maxOpacity: this.heatmapConfig.maxOpacity,
      });

      this.heatmapInstance.setData({
        min: this.heatmapConfig.min,
        max: this.heatmapConfig.max,
        data: this.floor.devicePositions.map((dP) => this.calculatePosition(dP.position)),
      });
    },
    calculatePosition(position) {
      const x = parseInt(
        this.imageX ? map(position.x, 0, this.floor.mapMedia.metadata.width, 0, this.imageX) : position.x, 10,
      );
      const y = parseInt(
        this.imageY ? map(position.y, 0, this.floor.mapMedia.metadata.height, 0, this.imageY) : position.y, 10,
      );

      return { x, y };
    },
    onFloorChange(floor) {
      this.$store.dispatch('floor/getFloorHeatmap', { id: floor.id });
    },
    onLoad() {
      const imgElement = this.$refs.mapImage.$el;
      this.imageX = imgElement.clientWidth;
      this.imageY = imgElement.clientHeight;

      // eslint-disable-next-line no-restricted-syntax
      for (const child of imgElement.children) {
        if (child.nodeName === 'CANVAS') {
          imgElement.removeChild(child);
        }
      }

      this.heatmapInstance = h337.create({
        radius: this.heatmapConfig.radius,
        maxOpacity: this.heatmapConfig.maxOpacity,
        blur: this.heatmapConfig.blur,
        container: imgElement,
      });

      this.heatmapInstance.setData({
        min: this.heatmapConfig.min,
        max: this.heatmapConfig.max,
        data: this.floor.devicePositions.map((dP) => this.calculatePosition(dP.position)),
      });
    },
  },
};
</script>

<style scoped>

</style>
