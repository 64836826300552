import axios from './axios';

async function listTours(exhibitionId, page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: `/admin/exhibitions/${exhibitionId}/tours`,
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function getTour(exhibitionId, id) {
  const response = await axios({
    method: 'get',
    url: `/admin/exhibitions/${exhibitionId}/tours/${id}`,
  });

  return response.data;
}

async function createTour(exhibitionId, data) {
  const response = await axios({
    method: 'post',
    url: `/admin/exhibitions/${exhibitionId}/tours`,
    data,
  });

  return response.data;
}

async function deleteTour(exhibitionId, id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/exhibitions/${exhibitionId}/tours/${id}`,
  });

  return response.data;
}

async function updateTour(exhibitionId, id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/exhibitions/${exhibitionId}/tours/${id}`,
    data,
  });

  return response.data;
}

async function updateTourExhibitionItems(exhibitionId, id, exhibitionItems) {
  const response = await axios({
    method: 'put',
    url: `/admin/exhibitions/${exhibitionId}/tours/${id}/items`,
    data: { exhibitionItems },
  });

  return response.data;
}

export default {
  listTours,
  getTour,
  createTour,
  deleteTour,
  updateTour,
  updateTourExhibitionItems,
};
