/<template>
  <v-expansion-panels v-if="devices.length">
    <v-expansion-panel>

      <v-expansion-panel-header>
        {{ $t('drawer.devices') }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>

        <v-data-table
          :headers="headers"
          :items="devices"
          :options.sync="options"
          :items-per-page="-1"
          :server-items-length="totalDevices"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-btn plain color="primary" :to="`/devices/new`">
              {{ $t('devices.list.newDevice') }}
            </v-btn>
          </template>

          <template v-slot:item.drag="{ item }">
            <v-icon draggable="true"
                    @dragstart='startDrag($event, item)'>
              mdi-drag
            </v-icon>
          </template>

          <template v-slot:item.open="{ item }">
            <v-btn icon small :to="`/devices/${item.id}`" >
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              small
              class="mr-2"
              :to="`/devices/${item.id}/update`">
              <v-icon
                small
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              small
              @click="deleteDevice(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
          </template>

          <template v-slot:item.updatedAt="{ item }">
            <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
          </template>
        </v-data-table>

      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>

  <v-row  v-else justify="center" align="center" class="max-height">
    {{ $t('devices.list.noDevice') }}
    <v-btn plain color="primary" to="/devices/new">
      {{ $t('devices.list.newDevice') }}
    </v-btn>
  </v-row>

</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import i18n from '@/i18n';

export default {
  name: 'DeviceList',
  props: {
    showDraggableColumn: Boolean,
    bus: Object,
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    getDataFromApi() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$store.dispatch('device/listDevices', {
        page,
        itemsPerPage,
      });
    },

    async deleteDevice({ id, name }) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('devices.deleteConfirm'),
        { color: 'red', itemName: name },
      )) {
        await this.$store.dispatch('device/deleteDevice', { id });

        // If the removed item was the last one in the page we need to go to the previous page
        if (this.devices.length - 1 <= 0 && this.options.page > 1) {
          this.options.page -= 1;
        }

        this.getDataFromApi();
      }
    },

    startDrag: (event, device) => {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', device.id);
      event.dataTransfer.setData('type', 'device');
    },
  },
  created() {
    this.$store.dispatch('device/listDevices', {});
  },
  mounted() {
    this.bus.$on('refresh', this.getDataFromApi);
  },
  computed: {
    devices() {
      return this.$store.state.device.devices;
    },
    totalDevices() {
      return this.$store.state.device.totalDevices;
    },
    loading() {
      return this.$store.state.device.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        ...(this.showDraggableColumn ? [
          {
            text: i18n.t('devices.list.drag'),
            value: 'drag',
            sortable: false,
            width: '64px',
          },
        ] : []),
        {
          text: i18n.t('devices.list.open'),
          value: 'open',
          sortable: false,
        },
        {
          text: i18n.t('devices.list.name'),
          value: 'name',
          sortable: false,
        },
        // {
        //   text: i18n.t('devices.list.uuid'),
        //   value: 'uuid',
        //   sortable: false,
        // },
        {
          text: i18n.t('devices.list.major'),
          value: 'major',
          sortable: false,
        },
        {
          text: i18n.t('devices.list.minor'),
          value: 'minor',
          sortable: false,
        },
        {
          text: i18n.t('devices.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('devices.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('devices.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style scoped>

</style>
