<template>
  <v-container>

    <v-expansion-panels v-if="exhibition" :value="0">
      <v-expansion-panel>

        <v-expansion-panel-header>
          {{ $t('exhibitions.exhibitionDetails') }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <h1>
            {{exhibition.title[language]}}
          </h1>

          <v-img
            class="mt-4 mb-4"
            :src="exhibition.coverImage"
          >
          </v-img>

          <div class="text-body-1">
            <viewer
              ref="toastuiViewer"
              v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
              :initialValue="exhibition.description[language]"
              :options="{ usageStatistics: false }" />
          </div>

          <div class="actions">
            <v-btn
              color="primary"
              :to="`/exhibitions/${exhibition.id}/update`"
            >
              {{ $t('common.update') }}
            </v-btn>

            <v-spacer />

            <v-btn
              color="error"
              @click="deleteExhibition(exhibition)"
            >
              {{ $t('common.delete') }}
            </v-btn>
          </div>

        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <template v-if="exhibition">
      <FloorSelector :on-change="onFloorChange"/>

      <FloorMap
        :floor="floor"
        :devices="devices"
        :exhibition-items="exhibitionItems"
        :exhibition-id="exhibition.id"
        :on-drop-event="onDropEvent"
        class="mb-4"/>

      <ExhibitionItemList
        :bus="busses.exhibitionItem"
        :exhibitionId="this.$route.params.id"
        :show-draggable-column="!!floor"
      />

      <DeviceList
        :bus="busses.device"
        :show-draggable-column="!!floor"
        class="mt-4" />
    </template>

    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { Viewer } from '@toast-ui/vue-editor';

import i18n from '@/i18n';
import FloorMap from '@/components/FloorMap';
import DeviceList from '@/components/DeviceList';
import FloorSelector from '@/components/FloorSelector';
import ExhibitionItemList from '@/components/ExhibitionItemList';

export default {
  name: 'ShowExhibition',
  components: {
    Viewer,
    FloorMap,
    DeviceList,
    FloorSelector,
    ExhibitionItemList,
  },
  created() {
    this.$store.dispatch('exhibition/getExhibition', { id: this.$route.params.id });
    this.$store.dispatch('floor/removeSelectedFloor');
    this.$store.dispatch('device/listDevices', {
      itemsPerPage: -1,
    });
  },
  data() {
    return {
      busses: {
        exhibitionItem: new Vue(),
        device: new Vue(),
      },
    };
  },
  computed: {
    exhibition() {
      return this.$store.state.exhibition.selectedExhibition;
    },
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    devices() {
      return this.$store.state.device.devices;
    },
    exhibitionItems() {
      return this.$store.state.exhibitionItem.exhibitionItems;
    },
    loading() {
      return this.$store.state.exhibition.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async deleteExhibition(exhibition) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('exhibitions.deleteConfirm'),
        { color: 'red', itemName: exhibition.title[this.language] },
      )) {
        await this.$store.dispatch('exhibition/deleteExhibition', exhibition);
        this.$router.push('/exhibitions');
      }
    },

    onFloorChange(floor) {
      this.$store.dispatch('floor/getFloor', { id: floor.id });
      this.$store.dispatch('gateway/listGateways', { floorId: floor.id });
      this.$store.dispatch('device/listDevices', {
        itemsPerPage: -1,
      });
    },

    onDropEvent(itemType) {
      const bus = this.busses[itemType];
      if (bus) {
        bus.$emit('refresh');
      }
    },
  },
  watch: {
    language() {
      this.$refs.toastuiViewer.invoke('setMarkdown', this.exhibition.description[this.language], true);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  display: flex;
  padding: 16px 0;

  .v-btn + .v-btn {
    margin-left: 8px;
  }
}
</style>
