import axios from './axios';

async function listPois(floorId, page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: '/admin/pois',
    params: {
      floorId, page, itemsPerPage,
    },
  });

  return response.data;
}

async function getPoi(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/pois/${id}`,
  });

  return response.data;
}

async function createPoi(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/pois',
    data,
  });

  return response.data;
}

async function deletePoi(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/pois/${id}`,
  });

  return response.data;
}

async function updatePoi(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/pois/${id}`,
    data,
  });

  return response.data;
}

export default {
  getPoi,
  createPoi,
  deletePoi,
  updatePoi,
  listPois,
};
