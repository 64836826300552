<template>
  <v-container>
    <template v-if="staticSite">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        class="mb-4"
      >
        <v-card-title>
          {{staticSite.title[language]}}
        </v-card-title>

        <v-card-text>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('staticSites.form.name') }}</v-list-item-title>
                <v-list-item-subtitle>{{ staticSite.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('staticSites.form.slug') }}</v-list-item-title>
                <v-list-item-subtitle>{{ staticSite.slug }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <viewer
            ref="toastuiViewer"
            v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
            :initialValue="staticSite.description[language]"
            :options="{ usageStatistics: false }" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/static-sites/${staticSite.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="deleteStaticSite(staticSite)"
          >
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor';

import i18n from '@/i18n';

export default {
  name: 'ShowStaticSite',
  components: {
    Viewer,
  },
  created() {
    this.$store.dispatch('staticSite/getStaticSite', { id: this.$route.params.id });
  },
  watch: {
    language() {
      this.$refs.toastuiViewer.invoke('setMarkdown', this.staticSite.description[this.language], true);
    },
  },
  computed: {
    staticSite() {
      return this.$store.state.staticSite.selectedStaticSite;
    },
    loading() {
      return this.$store.state.staticSite.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async deleteStaticSite(staticSite) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('staticSites.deleteConfirm'),
        { color: 'red', itemName: staticSite.name },
      )) {
        await this.$store.dispatch('staticSite/deleteStaticSite', staticSite);
        this.$router.push('/static-sites');
      }
    },
  },
};
</script>

<style scoped>

</style>
