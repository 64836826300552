<template>
  <v-container>

    <template v-if="newsList.length">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        v-for="news of newsList"
        :key="news.id"
        class="mb-4"
      >
        <v-card-title>
          {{news.title[language]}}
        </v-card-title>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/news/${news.id}`"
          >
            {{ $t('common.show') }}
          </v-btn>

          <v-btn
            color="primary"
            :to="`/news/${news.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="error"
            @click="deleteNews(news)"
          >
            {{ $t('common.delete') }}
          </v-btn>

        </v-card-actions>
      </v-card>

      <v-btn plain color="primary" to="/news/new">
        {{ $t('news.list.newNews') }}
      </v-btn>

    </template>

    <template v-else>
      <v-row justify="center" align="center" class="max-height">
        {{ $t('news.list.noNews') }}
        <v-btn plain color="primary" to="/news/new">
          {{ $t('news.list.newNews') }}
        </v-btn>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'NewsList',
  components: {},
  data: () => ({
    dialog: false,
  }),
  created() {
    this.$store.dispatch('news/listNews');
  },
  computed: {
    newsList() {
      return this.$store.state.news.news;
    },
    loading() {
      return this.$store.state.news.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async deleteNews(news) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('news.deleteConfirm'),
        { color: 'red', itemName: news.title[this.language] },
      )) {
        await this.$store.dispatch('news/deleteNews', news);
        this.$store.dispatch('news/listNews');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
