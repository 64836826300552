<template>
  <v-container>
    <template v-if="floor">

      <h1>
        {{floor.name[language]}}
      </h1>

      <v-row>
        <v-col>
          <div class="map-container">
            <img
              ref="mapImage"
              class="map-img"
              :src="floor.map"
              @load="onLoad"
              @click="onMapClick"
            />
            <canvas ref="canvas" class="canvas" @click="onMapClick"/>
          </div>
        </v-col>
      </v-row>

      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('floors.floorNumber') }}</v-list-item-title>
            <v-list-item-subtitle>{{floor.floorNumber}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('floors.form.scaleX') }}</v-list-item-title>
            <v-list-item-subtitle>{{floor.scaleX}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t('floors.form.scaleY') }}</v-list-item-title>
            <v-list-item-subtitle>{{floor.scaleY}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="actions">
        <v-btn
          color="primary"
          :to="`/floors/${floor.id}/update`"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteFloor(floor)"
        >
          {{ $t('common.delete') }}
        </v-btn>
      </div>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
/* eslint-disable no-param-reassign */
import i18n from '@/i18n';

import { map } from '@/utils';

export default {
  name: 'ShowFloor',
  data() {
    return {
      imageX: 0,
      imageY: 0,
    };
  },
  watch: {
    language() {
      this.renderRooms();
    },
  },
  created() {
    this.$store.dispatch('floor/getFloor', { id: this.$route.params.id });
    this.$store.dispatch('gateway/listGateways', { floorId: this.$route.params.id });
  },
  computed: {
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    gateways() {
      return this.$store.state.gateway.gateways;
    },
    loading() {
      return this.$store.state.floor.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async deleteFloor(floor) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('floors.deleteConfirm'),
        { color: 'red', itemName: floor.name[this.language] },
      )) {
        await this.$store.dispatch('floor/deleteFloor', floor);
        this.$router.push('/floors');
      }
    },
    renderRooms() {
      if (!this.$refs.canvas) {
        return;
      }
      const { canvas } = this.$refs;
      canvas.width = this.imageX;
      canvas.height = this.imageY;
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, this.imageX, this.imageY);

      // Render existing rooms
      if (this.floor && this.floor.rooms.length) {
        this.floor.rooms.forEach((room) => {
          ctx.beginPath();

          room.position.reduce((accumulator, currentValue, index) => {
            // It starts from index 1 when there is no initial value passed for reduce
            if (index === 1) {
              const calculatedAccumulator = this.calculatePosition(accumulator);
              ctx.moveTo(calculatedAccumulator.x, calculatedAccumulator.y);
            }

            const calculatedCurrent = this.calculatePosition(currentValue);
            ctx.lineTo(calculatedCurrent.x, calculatedCurrent.y);

            return currentValue;
          });

          ctx.closePath();
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 5;
          ctx.stroke();
          ctx.fillStyle = '#999D'; // A bit transparent
          ctx.fill();

          const textPosition = this.calculatePosition({
            x: room.centroid.x || (room.position[0].x + room.position[2].x) / 2,
            y: room.centroid.y || (room.position[0].y + room.position[2].y) / 2,
          });

          ctx.beginPath();
          ctx.font = '24px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center'; // Horizontal alignment
          ctx.textBaseline = 'middle'; // Vertical alignment
          ctx.fillText(room.name[this.language], textPosition.x, textPosition.y);
          ctx.closePath();
        });
      }

      this.renderGateways(ctx);
    },
    renderGateways(ctx) {
      this.gateways.forEach((gateway) => {
        if (gateway.sourceFloorId !== gateway.targetFloorId) {
          if (gateway.sourceFloorId === this.floor.id) {
            const position = this.calculatePosition(gateway.sourcePosition);
            ctx.strokeStyle = '#000';
            ctx.lineWidth = 3;
            ctx.fillStyle = '#922';

            ctx.beginPath();
            ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
          } else if (gateway.targetFloorId === this.floor.id) {
            const position = this.calculatePosition(gateway.targetPosition);
            ctx.strokeStyle = '#000';
            ctx.lineWidth = 3;
            ctx.fillStyle = '#955';

            ctx.beginPath();
            ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
          }
        } else if (gateway.sourceFloorId === this.floor.id) {
          const sourcePosition = this.calculatePosition(gateway.sourcePosition);
          const targetPosition = this.calculatePosition(gateway.targetPosition);
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 3;
          ctx.fillStyle = '#922';

          // Source
          ctx.beginPath();
          ctx.arc(sourcePosition.x, sourcePosition.y, 5, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

          // Target
          ctx.beginPath();
          ctx.arc(targetPosition.x, targetPosition.y, 5, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

          // Line between
          ctx.beginPath();
          ctx.moveTo(sourcePosition.x, sourcePosition.y);
          ctx.lineTo(targetPosition.x, targetPosition.y);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();
        }
      });
    },
    onLoad() {
      setTimeout(() => {
        if (this.$refs.mapImage) {
          const imgElement = this.$refs.mapImage;
          this.imageX = imgElement.clientWidth;
          this.imageY = imgElement.clientHeight;
        }

        this.renderRooms();
      }, 0);
    },
    calculatePosition(position) {
      const x = this.imageX ? map(position.x, 0, this.floor.mapMedia.metadata.width, 0, this.imageX) : position.x;
      const y = this.imageY ? map(position.y, 0, this.floor.mapMedia.metadata.height, 0, this.imageY) : position.y;

      return { x, y };
    },
    onMapClick(event) {
      console.log({
        x: Math.round(map(event.layerX, 0, event.target.clientWidth, 0, this.floor.mapMedia.metadata.width)),
        y: Math.round(map(event.layerY, 0, event.target.clientHeight, 0, this.floor.mapMedia.metadata.height)),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  display: flex;
  padding: 16px 0;

  .v-btn + .v-btn {
    margin-left: 8px;
  }
}

.map-container {
  position: relative;

  .map-img {
    width: 100%;
    height: 100%;
  }
  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
