import axios from './axios';

async function listStaticSites(page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: '/admin/static-sites',
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function getStaticSite(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/static-sites/${id}`,
  });

  return response.data;
}

async function createStaticSite(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/static-sites',
    data,
  });

  return response.data;
}

async function deleteStaticSite(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/static-sites/${id}`,
  });

  return response.data;
}

async function updateStaticSite(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/static-sites/${id}`,
    data,
  });

  return response.data;
}

export default {
  listStaticSites,
  getStaticSite,
  createStaticSite,
  deleteStaticSite,
  updateStaticSite,
};
