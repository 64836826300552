import axios from './axios';

async function listGateways(floorId, page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: '/admin/gateways',
    params: {
      floorId, page, itemsPerPage,
    },
  });

  return response.data;
}

async function getGateway(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/gateways/${id}`,
  });

  return response.data;
}

async function createGateway(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/gateways',
    data,
  });

  return response.data;
}

async function deleteGateway(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/gateways/${id}`,
  });

  return response.data;
}

async function updateGateway(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/gateways/${id}`,
    data,
  });

  return response.data;
}

export default {
  getGateway,
  createGateway,
  deleteGateway,
  updateGateway,
  listGateways,
};
