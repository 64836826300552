<template>
  <v-container>

    Home page - TODO

  </v-container>
</template>

<script>

export default {
  name: 'Home',
  components: {},
};
</script>
