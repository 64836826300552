import axios from './axios';

async function login({ email, password }) {
  const response = await axios({
    method: 'post',
    url: '/admin/login',
    data: { email, password },
  });
  const { user, token } = response.data;
  localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem('token', JSON.stringify(token));

  return { user, token };
}

async function logout() {
  await axios({
    method: 'post',
    url: '/admin/logout',
  });
  localStorage.removeItem('user');
  localStorage.removeItem('token');

  return true;
}

async function register({
  email, password, passwordConfirmation, name,
}) {
  await axios({
    method: 'post',
    url: '/admin/register',
    data: {
      email, password, passwordConfirmation, name,
    },
  });

  return true;
}

const isAuthenticated = () => localStorage.getItem('token');

export default {
  login,
  logout,
  register,
  isAuthenticated,
};
