import axios from './axios';

async function listDevices(page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: '/admin/devices',
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function getDevice(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/devices/${id}`,
  });

  return response.data;
}

async function createDevice(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/devices',
    data,
  });

  return response.data;
}

async function deleteDevice(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/devices/${id}`,
  });

  return response.data;
}

async function updateDevice(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/devices/${id}`,
    data,
  });

  return response.data;
}

export default {
  listDevices,
  getDevice,
  createDevice,
  deleteDevice,
  updateDevice,
};
