/* eslint-disable no-console */
import { staticSite as staticSiteService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  staticSites: [],
  totalStaticSites: 0,
  selectedStaticSite: null,
  loading: false,
};

const actions = {
  async listStaticSites({ commit }, { page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const staticSites = await staticSiteService.listStaticSites(page, itemsPerPage);
      commit('listStaticSitesSuccess', {
        total: staticSites.meta.total,
        staticSites: staticSites.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getStaticSite({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const staticSite = await staticSiteService.getStaticSite(id);
      commit('getStaticSiteSuccess', staticSite);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createStaticSite({ commit }, {
    data,
  }) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return staticSiteService
      .createStaticSite(formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteStaticSite({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await staticSiteService.deleteStaticSite(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateStaticSite(
    { commit },
    {
      id, data,
    },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return staticSiteService
      .updateStaticSite(id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
  clearSelected({ commit }) {
    commit('clearSelected');
  },
};

const mutations = {
  listStaticSitesSuccess(state, { staticSites, total }) {
    state.staticSites = staticSites;
    state.totalStaticSites = total;
  },
  getStaticSiteSuccess(state, staticSite) {
    state.selectedStaticSite = staticSite;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  clearSelected(state) {
    state.selectedStaticSite = null;
  },
};

const getters = {
  staticSites(state) {
    return state.staticSites;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
