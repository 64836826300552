<template>
  <v-container>

    <template v-if="exhibitions.length">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        v-for="exhibition of exhibitions"
        :key="exhibition.id"
        class="mb-4"
      >
        <v-img
          :src="exhibition.coverImage"
          height="200px"
        >
        </v-img>

        <v-card-title>
          {{exhibition.title[language]}}
        </v-card-title>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/exhibitions/${exhibition.id}`"
          >
            {{ $t('common.show') }}
          </v-btn>

          <v-btn
            color="primary"
            :to="`/exhibitions/${exhibition.id}/tours`"
          >
            {{ $t('exhibitions.tours') }}
          </v-btn>

          <v-btn
            color="primary"
            :to="`/exhibitions/${exhibition.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="error"
            @click="deleteExhibition(exhibition)"
          >
            {{ $t('common.delete') }}
          </v-btn>

        </v-card-actions>
      </v-card>

      <v-btn color="secondary" to="/exhibitions/new">
        {{ $t('exhibitions.list.newExhibition') }}
      </v-btn>

    </template>

    <template v-else>
      <v-row justify="center" align="center" class="max-height">
        {{ $t('exhibitions.list.noExhibition') }}
        <v-btn plain color="primary" to="/exhibitions/new">
          {{ $t('exhibitions.list.newExhibition') }}
        </v-btn>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'ExhibitionList',
  components: {},
  data: () => ({
    dialog: false,
  }),
  created() {
    this.$store.dispatch('exhibition/listExhibitions');
  },
  computed: {
    exhibitions() {
      return this.$store.state.exhibition.exhibitions;
    },
    loading() {
      return this.$store.state.exhibition.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async deleteExhibition(exhibition) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('exhibitions.deleteConfirm'),
        { color: 'red', itemName: exhibition.title[this.language] },
      )) {
        this.$store.dispatch('exhibition/deleteExhibition', exhibition);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
