<template>
  <v-container>
    <template v-if="quiz">

      <h1>
        {{quiz.name[language]}}
      </h1>

      <v-expansion-panels v-if="quiz.coverImageMedia" class="mt-4 mb-4">
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t('quizzes.form.coverImage')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <Media :media="quiz.coverImageMedia"></Media>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-list v-if="quiz.questions.length">
        <v-list-item v-for="question in quiz.questions" :key="question.id">
          <v-list-item-content>
            <v-list-item-title>{{ question.question[language] }}</v-list-item-title>
            <v-list-item-subtitle>{{ question.answers[question.solution][language] }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div class="actions">
        <v-btn
          color="primary"
          :to="`/quizzes/${quiz.id}/update`"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteQuiz(quiz)"
        >
          {{ $t('common.delete') }}
        </v-btn>
      </div>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import i18n from '@/i18n';
import Media from '@/components/Media';

export default {
  name: 'ShowQuiz',
  components: {
    Media,
  },
  created() {
    this.$store.dispatch('quiz/getQuiz', { id: this.$route.params.id });
  },
  computed: {
    quiz() {
      return this.$store.state.quiz.selectedQuiz;
    },
    loading() {
      return this.$store.state.quiz.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async deleteQuiz(quiz) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('quizzes.deleteConfirm'),
        { color: 'red', itemName: quiz.name[this.language] },
      )) {
        await this.$store.dispatch('quiz/deleteQuiz', quiz);
        this.$router.push('/quizzes');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  display: flex;
  padding: 16px 0;

  .v-btn + .v-btn {
    margin-left: 8px;
  }
}
</style>
