<template>
  <v-alert
    :dismissible="dismissable"
    :elevation="elevation"
    :type="type"
  >
    <template v-slot:close>
      <v-btn
        icon
        small
        @click="onClose"
      >
        <v-icon color="white">mdi-close-circle</v-icon>
      </v-btn>
    </template>
    {{message}}
  </v-alert>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    dismissable: Boolean,
    elevation: String,
    type: String,
    message: String,
    onClose: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
