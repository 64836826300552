<template>
  <v-container>
    <template v-if="exhibitionItem">

      <h1>
        {{exhibitionItem.name[language]}}
      </h1>

      <div class="text-body-1">
        <viewer
          ref="toastuiEditor"
          v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
          :initialValue="exhibitionItem.description[language]"
          :options="{ usageStatistics: false }"
        />
      </div>

      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title>{{ $t('exhibitionItems.form.isHidden') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t(exhibitionItem.isHidden ? 'common.yes' : 'common.no') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-carousel v-model="carousel" height="auto">
        <v-carousel-item
          v-for="media in exhibitionItem.media" :key="media.id"
        >
          <Media :media="media"></Media>
        </v-carousel-item>
      </v-carousel>

      <v-expansion-panels class="mt-4">
        <v-expansion-panel>
          <v-expansion-panel-header>{{ $t('exhibitionItems.manageMedia') }}</v-expansion-panel-header>

          <v-expansion-panel-content>

            <v-data-table
              :headers="headers"
              :items="exhibitionItem.media"
              :loading="loading"
              hide-default-footer
            >
              <template v-slot:item.actions="{ item, index }">
                <v-btn
                  icon
                  v-if="index > 0"
                  @click="moveUp(item)"
                  :disabled="loading"
                >
                  <v-icon>
                    mdi-chevron-up
                  </v-icon>
                </v-btn>

                <div v-else class="empty-button"></div>

                <v-btn
                  icon
                  v-if="index < exhibitionItem.media.length - 1"
                  @click="moveDown(item)"
                  :disabled="loading"
                >
                  <v-icon>
                    mdi-chevron-down
                  </v-icon>
                </v-btn>

                <div v-else class="empty-button"></div>

                <v-btn
                  icon
                  @click="removeItem(item)"
                  :disabled="loading"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>

            </v-data-table>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <div class="actions">
        <v-btn
          color="primary"
          :to="`/exhibitions/${exhibitionId}/exhibition-items/${exhibitionItem.id}/update`"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="error"
          @click="deleteExhibitionItem(exhibitionItem)"
        >
          {{ $t('common.delete') }}
        </v-btn>
      </div>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
// eslint-disable import/no-unresolved
import { Viewer } from '@toast-ui/vue-editor';

import i18n from '@/i18n';
import Media from '@/components/Media';

export default {
  name: 'ShowExhibitionItem',
  created() {
    this.loadExhibitionItem();
  },
  components: {
    Media,
    Viewer,
  },
  data() {
    return {
      carousel: null,
      exhibitionId: this.$route.params.id,
    };
  },
  computed: {
    exhibitionItem() {
      return this.$store.state.exhibitionItem.selectedExhibitionItem;
    },
    loading() {
      return this.$store.state.exhibitionItem.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('exhibitionItems.list.name'),
          value: 'metadata.fileName',
          sortable: false,
        },
        { text: i18n.t('tours.list.actions'), value: 'actions', sortable: false },
      ];
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async loadExhibitionItem() {
      await this.$store.dispatch('exhibitionItem/getExhibitionItem', {
        exhibitionId: this.exhibitionId,
        id: this.$route.params.exhibitionItemId,
      });

      this.$refs.toastuiEditor.invoke('setMarkdown', this.exhibitionItem.description[this.language], true);
    },
    async deleteExhibitionItem(exhibitionItem) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('exhibitionItems.deleteConfirm'),
        { color: 'red', itemName: exhibitionItem.name[this.language] },
      )) {
        await this.$store.dispatch('exhibitionItem/deleteExhibitionItem', {
          exhibitionId: this.exhibitionId,
          id: this.$route.params.exhibitionItemId,
        });
        this.$router.push(`/exhibitions/${this.exhibitionId}`);
      }
    },

    async moveUp(exhibitionItem) {
      const mediaIndex = this.exhibitionItem.media.findIndex(({ id }) => id === exhibitionItem.id);
      if (mediaIndex === -1) {
        return;
      }

      const medias = [...this.exhibitionItem.media];
      const [prevItem, currentItem] = medias.slice(mediaIndex - 1, mediaIndex + 1);

      medias.splice(mediaIndex - 1, 2,
        { ...currentItem, order: prevItem.order },
        { ...prevItem, order: currentItem.order });

      await this.$store.dispatch('exhibitionItem/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.exhibitionItem.id,
        media: medias
          .map(({ id: mediaId, order }) => ({ mediaId, order })),
      });

      this.loadExhibitionItem();
    },

    async moveDown(media) {
      const mediaIndex = this.exhibitionItem.media.findIndex(({ id }) => id === media.id);
      if (mediaIndex === -1) {
        return;
      }

      const medias = [...this.exhibitionItem.media];
      const [currentItem, nextItem] = medias.slice(mediaIndex, mediaIndex + 2);

      medias.splice(mediaIndex, 2,
        { ...nextItem, order: currentItem.order },
        { ...currentItem, order: nextItem.order });

      await this.$store.dispatch('exhibitionItem/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.exhibitionItem.id,
        media: medias
          .map(({ id: mediaId, order }) => ({ mediaId, order })),
      });

      this.loadExhibitionItem();
    },

    async removeItem(media) {
      const mediaIndex = this.exhibitionItem.media.findIndex(({ id }) => id === media.id);
      if (mediaIndex === -1) {
        return;
      }
      const medias = [...this.exhibitionItem.media];
      medias.splice(mediaIndex, 1);

      await this.$store.dispatch('exhibitionItem/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.exhibitionItem.id,
        media: medias
          .map(({ id: mediaId, order }) => ({ mediaId, order })),
      });

      this.loadExhibitionItem();
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-button {
  width: 36px;
  height: 36px;
  display: inline-flex;
  vertical-align: middle;
}
.actions {
  align-items: center;
  display: flex;
  padding: 16px 0;

  .v-btn + .v-btn {
    margin-left: 8px;
  }
}
</style>
