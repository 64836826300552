<template>
  <v-container>

    <template>
      <FloorSelector :on-change="onFloorChange"/>

      <FloorMap
        v-if="floor"
        :floor="floor"
        :pois="pois"
        :on-drop-event="onDropEvent"
        class="mb-4"/>

      <PoiList
        :bus="busses.poi"
        :show-draggable-column="!!floor"
      />

    </template>

  </v-container>
</template>

<script>
import Vue from 'vue';

import PoiList from '@/components/PoiList';
import FloorMap from '@/components/FloorMap';
import FloorSelector from '@/components/FloorSelector';

export default {
  name: 'FloorList',
  components: {
    PoiList,
    FloorMap,
    FloorSelector,
  },
  data: () => ({
    dialog: false,
    busses: {
      poi: new Vue(),
    },
  }),
  created() {
    this.$store.dispatch('floor/listFloors');
    this.$store.dispatch('floor/removeSelectedFloor');
  },
  computed: {
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    pois() {
      return this.$store.state.poi.pois;
    },
    loading() {
      return this.$store.state.floor.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onFloorChange(floor) {
      this.$store.dispatch('floor/getFloor', { id: floor.id });
      this.$store.dispatch('poi/listPois', { floorId: floor.id });
    },
    // onDropEvent(itemType) {
    //   if (itemType === 'poi') {
    //     this.$store.dispatch('poi/listPois', { floorId: this.floor.id });
    //   }
    // },
    onDropEvent(itemType) {
      const bus = this.busses[itemType];
      if (bus) {
        bus.$emit('refresh');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
