<template>
  <v-container>
    <template v-if="device">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        class="mb-4"
      >
        <v-card-title>
          {{device.name}}
        </v-card-title>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('devices.list.uuid') }}</v-list-item-title>
            <v-list-item-subtitle>{{device.uuid}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('devices.list.major') }}</v-list-item-title>
            <v-list-item-subtitle>{{device.major}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('devices.list.minor') }}</v-list-item-title>
            <v-list-item-subtitle>{{device.minor}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('devices.list.measuredPower') }}</v-list-item-title>
            <v-list-item-subtitle>{{device.measuredPower}} dBm</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title>{{ $t('devices.list.height') }}</v-list-item-title>
            <v-list-item-subtitle>{{device.position.z || 0}} cm</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/devices/${device.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="deleteDevice(device)"
          >
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'ShowDevice',
  created() {
    this.$store.dispatch('device/getDevice', { id: this.$route.params.id });
  },
  computed: {
    device() {
      return this.$store.state.device.selectedDevice;
    },
    loading() {
      return this.$store.state.device.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async deleteDevice(device) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('devices.deleteConfirm'),
        { color: 'red', itemName: device.name },
      )) {
        await this.$store.dispatch('device/deleteDevice', device);
        this.$router.push('/devices');
      }
    },
  },
};
</script>

<style scoped>

</style>
