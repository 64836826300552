<template>
  <v-container>

    <ExhibitionSelector :on-change="onExhibitionChange"/>

    <TourList v-if="exhibition" :exhibitionId="exhibition.id" />

  </v-container>
</template>

<script>
import TourList from '@/components/TourList';
import ExhibitionSelector from '@/components/ExhibitionSelector';

export default {
  name: 'TourListPage2',
  components: {
    TourList,
    ExhibitionSelector,
  },
  data() {
    return {
      exhibitionId: this.$route.params.id,
    };
  },
  created() {
    this.$store.dispatch('exhibition/listExhibitions');
    this.$store.dispatch('exhibition/removeSelectedExhibition');
  },
  computed: {
    exhibition() {
      return this.$store.state.exhibition.selectedExhibition;
    },
    loading() {
      return this.$store.state.tour.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onExhibitionChange(exhibition) {
      this.$store.dispatch('exhibition/getExhibition', { id: exhibition.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
