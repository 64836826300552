<template>
  <v-container>

    <v-form
      ref="newsForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Name -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.hu"
            :label="$t('quizzes.form.nameHU')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.en"
            :label="$t('quizzes.form.nameEN')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.de"
            :label="$t('quizzes.form.nameDE')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <v-textarea
            v-model="description.hu"
            :label="$t('quizzes.form.descriptionHU')"
            :rules="descriptionRules"
            rows="2"
            required
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <v-textarea
            v-model="description.en"
            :label="$t('quizzes.form.descriptionEN')"
            :rules="descriptionRules"
            rows="2"
            required
          ></v-textarea>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <v-textarea
            v-model="description.de"
            :label="$t('quizzes.form.descriptionDE')"
            :rules="descriptionRules"
            rows="2"
            required
          ></v-textarea>
        </v-col>
      </v-row>

      <v-expansion-panels v-if="mediaPreview && !coverImage">
        <v-expansion-panel>
          <v-expansion-panel-header>{{$t('quizzes.form.coverImage')}}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <Media :media="coverImageMedia"></Media>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-file-input
        accept=".png, .jpg, .jpeg"
        :label="$t('quizzes.form.coverImage')"
        :rules="isUpdate ? mediaUpdateRules : mediaRules"
        v-model="coverImage"
      ></v-file-input>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import i18n from '@/i18n';
import Media from '@/components/Media';
import Alert from '@/components/Alert';

export default {
  name: 'NewsForm',
  components: {
    Alert,
    Media,
  },
  data() {
    return {
      valid: true,
      name: {
        hu: '',
        en: '',
        de: '',
      },
      description: {
        hu: '',
        en: '',
        de: '',
      },
      coverImage: null,
      coverImageMedia: null,
      mediaPreview: '',
      nameRules: [
        (v) => !!v || i18n.t('quizzes.form.nameRule'),
      ],
      descriptionRules: [
        (v) => !!v || i18n.t('exhibitions.form.descriptionRule'),
      ],
      mediaRules: [
        (v) => !!v || i18n.t('quizzes.form.mediaRule'),
        (v) => (!!v && v.size <= 20 * 1024 * 1024) || i18n.t('quizzes.form.mediaSizeRule'),
      ],
      mediaUpdateRules: [
        (v) => {
          if (this.isUpdate) return true;
          return (!!v && v.size <= 20 * 1024 * 1024) || i18n.t('quizzes.form.mediaSizeRule');
        },
      ],
      isUpdate: false,
    };
  },
  async created() {
    this.$store.dispatch('quiz/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('quiz/getQuiz', { id: this.$route.params.id });
      this.isUpdate = true;
      this.name = this.quiz.name;
      this.description = this.quiz.description;
      this.coverImageMedia = this.quiz.coverImageMedia;
      this.mediaPreview = this.quiz.coverImageMedia?.prefixedUrl;
    }
  },
  computed: {
    loading() {
      return this.$store.state.quiz.loading;
    },
    quiz() {
      return this.$store.state.quiz.selectedQuiz;
    },
    error() {
      return this.$store.state.quiz.error;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.newsForm.validate()) {
        const {
          name, coverImage, description,
        } = this;

        if (this.isUpdate) {
          const data = {
            name,
            description,
            ...(coverImage && { coverImage }),
          };
          await this.$store.dispatch('quiz/updateQuiz', {
            id: this.quiz.id,
            data,
          });
          this.$router.push(`/quizzes/${this.quiz.id}`);
        } else {
          const createdQuiz = await this.$store.dispatch('quiz/createQuiz', {
            name, coverImage, description,
          });
          this.$router.push(`/quizzes/${createdQuiz.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('quiz/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
