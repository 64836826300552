<template>
  <v-container>

    <v-form
      ref="floorForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Name -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.hu"
            :label="$t('floors.form.nameHU')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.en"
            :label="$t('floors.form.nameEN')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.de"
            :label="$t('floors.form.nameDE')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Floor Number and scales -->
      <v-row>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="floorNumber"
            type="number"
            :label="$t('floors.floorNumber')"
            :rules="floorNumberRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="scaleX"
            type="number"
            :label="$t('floors.form.scaleX')"
            :rules="scaleRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="scaleY"
            type="number"
            :label="$t('floors.form.scaleY')"
            :rules="scaleRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-img
        v-if="mapPreview && !map"
        :src="mapPreview"
        height="200px"
      >
      </v-img>

      <v-file-input
        accept=".png, .jpg, .jpeg"
        :label="$t('floors.form.map')"
        :rules="isUpdate ? mapUpdateRules : mapRules"
        v-model="map"
      ></v-file-input>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import i18n from '@/i18n';
import Alert from '@/components/Alert.vue';

export default {
  name: 'FloorForm',
  components: {
    Alert,
  },
  data() {
    return {
      valid: true,
      name: {
        hu: '',
        en: '',
        de: '',
      },
      floorNumber: null,
      map: null,
      mapPreview: '',
      scaleX: 0,
      scaleY: 0,
      scaleRules: [
        (v) => v >= 0 || i18n.t('floors.form.scaleRule'),
      ],
      nameRules: [
        (v) => !!v || i18n.t('floors.form.nameRule'),
      ],
      floorNumberRules: [
        (v) => v >= 0 || i18n.t('floors.form.floorNumberRule'),
      ],
      mapRules: [
        (v) => !!v || i18n.t('floors.form.mapRule'),
        (v) => (!!v && v.size <= 100 * 1024 * 1024) || i18n.t('floors.form.mapSizeRule'),
      ],
      mapUpdateRules: [
        (v) => {
          if (this.isUpdate) return true;
          return (!!v && v.size <= 100 * 1024 * 1024) || i18n.t('floors.form.mapSizeRule');
        },
      ],
      isUpdate: false,
    };
  },
  async created() {
    this.$store.dispatch('floor/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('floor/getFloor', { id: this.$route.params.id });
      this.isUpdate = true;
      this.name = this.floor.name;
      this.floorNumber = this.floor.floorNumber;
      this.mapPreview = this.floor.map;
      this.scaleX = this.floor.scaleX;
      this.scaleY = this.floor.scaleY;
    }
  },
  computed: {
    loading() {
      return this.$store.state.floor.loading;
    },
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    error() {
      return this.$store.state.floor.error;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.floorForm.validate()) {
        const {
          name, floorNumber, map, scaleX, scaleY,
        } = this;

        if (this.isUpdate) {
          const data = {
            name, floorNumber, scaleX, scaleY,
          };
          if (map) data.map = map;
          await this.$store.dispatch('floor/updateFloor', {
            id: this.floor.id,
            data,
          });
          this.$router.push(`/floors/${this.floor.id}`);
        } else {
          const createdFloor = await this.$store.dispatch('floor/createFloor', {
            name, floorNumber, map, scaleX, scaleY,
          });
          this.$router.push(`/floors/${createdFloor.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('floor/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
