<template>
  <v-app>
    <v-navigation-drawer
      app
      permanent
      expand-on-hover
    >
      <v-list>
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <img :src="user.profilePictureUrl">
          </v-list-item-avatar>
        </v-list-item>

        <v-list-item link>
          <v-list-item-content>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('drawer.loggedIn') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        nav
        dense
      >
          <v-list-group v-for="(route, i) in routeGroups" :key="`g-${i}`">
            <template v-if="route.children" v-slot:activator>
              <v-list-item-content class="flex-wrap-reverse flex-row text-no-wrap">
                <v-list-item-icon>
                  <v-icon>{{ route.icon }}</v-icon>
                  <v-list-item-title class="ml-2">{{ route.title }}</v-list-item-title>
                </v-list-item-icon>
              </v-list-item-content>
            </template>

            <template v-if="route.children">
              <v-list-item
                v-for="(child, j) in route.children"
                :to="child.to"
                :key="`gi-${j}`" link>
                <v-list-item-icon>
                  <v-icon>{{ child.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ child.title }}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

          <v-divider></v-divider>

        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ $t('drawer.logout') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="#34495e"
      dark
    >
      <v-toolbar-title>Museum Admin</v-toolbar-title>

      <v-spacer></v-spacer>

      <LanguageSelector />

      <v-btn icon v-on:click="toggleDarkMode">
        <v-icon>mdi-invert-colors</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid>
        <router-view></router-view>
        <ConfirmDialog ref="confirm"></ConfirmDialog>
      </v-container>
    </v-main>

    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog';
import LanguageSelector from '@/components/LanguageSelector';

export default {
  name: 'Admin',
  components: {
    ConfirmDialog,
    LanguageSelector,
  },
  data: () => ({
    drawer: false,
    group: null,
  }),

  methods: {
    logout() {
      this.$store.dispatch('authentication/logout');
    },
    toggleDarkMode() {
      this.$store.dispatch('theme/toggleTheme');
    },
  },

  computed: {
    routeGroups() {
      return [
        {
          title: this.$t('drawer.museum'),
          icon: 'mdi-bank',
          children: [
            { title: this.$t('drawer.floors'), icon: 'mdi-layers-triple', to: '/floors' },
            { title: this.$t('drawer.rooms'), icon: 'mdi-rectangle', to: '/rooms' },
            { title: this.$t('drawer.devices'), icon: 'mdi-access-point', to: '/devices' },
            { title: this.$t('drawer.poi'), icon: 'mdi-map-marker', to: '/pois' },
            { title: this.$t('drawer.news'), icon: 'mdi-newspaper', to: '/news' },
            { title: this.$t('drawer.staticSites'), icon: 'mdi-message-text', to: '/static-sites' },
          ],
        },
        {
          title: this.$t('drawer.exhibitionsMenu'),
          icon: 'mdi-image-multiple',
          children: [
            { title: this.$t('drawer.exhibitions'), icon: 'mdi-pencil', to: '/exhibitions' },
            { title: this.$t('drawer.exhibitionItems'), icon: 'mdi-camera', to: '/exhibition-items' },
            { title: this.$t('drawer.tours'), icon: 'mdi-map', to: '/tours' },
            { title: this.$t('drawer.quizzes'), icon: 'mdi-help-circle', to: '/quizzes' },
          ],
        },
        {
          title: this.$t('drawer.statistics'),
          icon: 'mdi-chart-box',
          children: [
            { title: this.$t('drawer.heatmap'), icon: 'mdi-fire', to: '/heatmap' },
          ],
        },
      ];
    },
    user() {
      return this.$store.state.authentication.user;
    },
  },

  mounted() {
    this.$root.$confirm = this.$refs.confirm;
    this.$vuetify.theme.dark = this.$store.state.theme.dark;
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'theme/toggleTheme') {
        this.$vuetify.theme.dark = state.theme.dark;
      }
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>
