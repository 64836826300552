/* eslint-disable no-console */
import { quiz as quizzesService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  quizzes: [],
  totalQuizzes: 0,
  selectedQuiz: null,
  loading: false,
};

const actions = {
  async listQuizzes({ commit }, { page, itemsPerPage } = {}) {
    commit('setLoading', true);
    try {
      const quizzes = await quizzesService.listQuizzes(page, itemsPerPage);
      commit('listQuizzesSuccess', {
        total: quizzes.meta.total,
        quizzes: quizzes.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getQuiz({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const quiz = await quizzesService.getQuiz(id);
      commit('getQuizzesSuccess', quiz);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createQuiz({ commit }, data) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return quizzesService
      .createQuiz(formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteQuiz({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await quizzesService.deleteQuiz(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateQuiz(
    { commit },
    { id, data },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return quizzesService
      .updateQuiz(id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listQuizzesSuccess(state, { total, quizzes }) {
    state.quizzes = quizzes;
    state.totalQuizzes = total;
  },
  getQuizzesSuccess(state, quiz) {
    state.selectedQuiz = quiz;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  quizzes(state) {
    return state.quizzes;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
