<template>
  <v-select
    :items="floors"
    :item-text="`name.${language}`"
    :label="$t('floorSelector.select')"
    :loading="loading"
    :return-object="true"
    @change="onChange"
  ></v-select>
</template>

<script>
export default {
  name: 'FloorSelector',
  created() {
    this.$store.dispatch('floor/listFloors');
  },
  props: {
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    selectorItems() {
      return [];
    },
    floors() {
      return this.$store.state.floor.floors;
    },
    loading() {
      return this.$store.state.floor.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>

</style>
