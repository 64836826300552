<template>
  <v-container>
    <v-form
      ref="roomForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Name -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.hu"
            :label="$t('rooms.form.nameHU')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.en"
            :label="$t('rooms.form.nameEN')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name.de"
            :label="$t('rooms.form.nameDE')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-switch
            v-model="isCorridor"
            :label="$t('rooms.list.isCorridor')"
          ></v-switch>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <v-expansion-panels class="mb-4" :value="0">
            <v-expansion-panel>

              <v-expansion-panel-header>
                {{$t('rooms.form.descriptionHU')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorHu"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.hu"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('rooms.form.descriptionEN')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorEn"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.en"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('rooms.form.descriptionDE')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorDe"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.de"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <v-row v-if="floor">
        <v-col>
          <v-expansion-panels class="mb-4">
            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('rooms.form.map')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <div class="map-container">
                  <img
                    ref="mapImage"
                    class="map-img"
                    :src="floor.map"
                    @load="onLoad"
                  />
                  <canvas ref="canvas" class="canvas" @click="onMapClick"/>
                </div>
              </v-expansion-panel-content>

            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';

import Alert from '@/components/Alert.vue';
import { map } from '@/utils';

export default {
  name: 'RoomForm',
  components: {
    Alert,
    Editor,
  },
  data() {
    return {
      valid: true,
      name: {
        hu: '',
        en: '',
        de: '',
      },
      description: {
        hu: '',
        en: '',
        de: '',
      },
      position: [],
      isCorridor: false,
      isUpdate: false,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
      isSelectingRoomPosition: true,
    };
  },
  async created() {
    this.$store.dispatch('room/clearError');
    await this.$store.dispatch('floor/getFloor', { id: this.floorId });
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('room/getRoom', { floorId: this.floorId, id: this.$route.params.id });
      this.isUpdate = true;
      this.name = this.room.name;
      this.description = this.room.description ? this.room.description : {
        hu: '', en: '', de: '',
      };
      this.isCorridor = this.room.isCorridor;

      if (this.$refs.toastuiEditorHu) {
        this.$refs.toastuiEditorHu.invoke('setMarkdown', this.description.hu ?? '', true);
      }
      if (this.$refs.toastuiEditorEn) {
        this.$refs.toastuiEditorEn.invoke('setMarkdown', this.description.en ?? '', true);
      }
      if (this.$refs.toastuiEditorDe) {
        this.$refs.toastuiEditorDe.invoke('setMarkdown', this.description.de ?? '', true);
      }
    }
  },
  computed: {
    loading() {
      return this.$store.state.room.loading;
    },
    room() {
      return this.$store.state.room.selectedRoom;
    },
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    error() {
      return this.$store.state.room.error;
    },
    floorId() {
      return this.$route.params.floorId;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.roomForm.validate()) {
        const {
          name, position, isCorridor,
        } = this;

        if (this.isUpdate) {
          const data = {
            name,
            position,
            isCorridor,
            description: {
              hu: this.$refs.toastuiEditorHu ? this.$refs.toastuiEditorHu.invoke('getMarkdown') : this.description.hu,
              en: this.$refs.toastuiEditorEn ? this.$refs.toastuiEditorEn.invoke('getMarkdown') : this.description.en,
              de: this.$refs.toastuiEditorDe ? this.$refs.toastuiEditorDe.invoke('getMarkdown') : this.description.de,
            },
          };
          await this.$store.dispatch('room/updateRoom', {
            id: this.room.id,
            floorId: this.floorId,
            data,
          });
          this.$router.push(`/floors/${this.floorId}/rooms/${this.room.id}`);
        } else {
          const createdRoom = await this.$store.dispatch('room/createRoom', {
            floorId: this.floorId,
            data: {
              name,
              position,
              isCorridor,
              description: {
                hu: this.$refs.toastuiEditorHu ? this.$refs.toastuiEditorHu.invoke('getMarkdown') : this.description.hu,
                en: this.$refs.toastuiEditorEn ? this.$refs.toastuiEditorEn.invoke('getMarkdown') : this.description.en,
                de: this.$refs.toastuiEditorDe ? this.$refs.toastuiEditorDe.invoke('getMarkdown') : this.description.de,
              },
            },
          });
          this.$router.push(`/floors/${this.floorId}/rooms/${createdRoom.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('room/clearError');
    },
    onLoad() {
      setTimeout(() => {
        if (this.$refs.mapImage) {
          const imgElement = this.$refs.mapImage;
          this.imageX = imgElement.clientWidth;
          this.imageY = imgElement.clientHeight;
        }

        this.renderRooms();
      }, 0);
    },
    renderRooms() {
      if (!this.$refs.canvas) {
        return;
      }
      const { canvas } = this.$refs;
      canvas.width = this.imageX;
      canvas.height = this.imageY;
      const ctx = canvas.getContext('2d');

      ctx.clearRect(0, 0, this.imageX, this.imageY);

      // Render existing rooms
      if (this.floor && this.floor.rooms.length) {
        this.floor.rooms.forEach((room) => {
          ctx.beginPath();

          room.position.reduce((accumulator, currentValue, index) => {
            // It starts from index 1 when there is no initial value passed for reduce
            if (index === 1) {
              const calculatedAccumulator = this.calculatePosition(accumulator);
              ctx.moveTo(calculatedAccumulator.x, calculatedAccumulator.y);
            }

            const calculatedCurrent = this.calculatePosition(currentValue);
            ctx.lineTo(calculatedCurrent.x, calculatedCurrent.y);

            return currentValue;
          });

          ctx.closePath();
          ctx.strokeStyle = this.$route.params.id && this.$route.params.id === room.id ? '#f00' : '#000';
          ctx.lineWidth = 5;
          ctx.stroke();
          ctx.fillStyle = '#999D'; // A bit transparent
          ctx.fill();

          const textPosition = this.calculatePosition({
            x: room.centroid.x || (room.position[0].x + room.position[2].x) / 2,
            y: room.centroid.y || (room.position[0].y + room.position[2].y) / 2,
          });

          ctx.beginPath();
          ctx.font = '24px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center'; // Horizontal alignment
          ctx.textBaseline = 'middle'; // Vertical alignment
          ctx.fillText(room.name[this.language], textPosition.x, textPosition.y);
          ctx.closePath();
        });
      }

      // Render the new room's polygon
      if (this.position.length >= 3) {
        ctx.beginPath();

        // Draw a dashed line for the in-progress room
        ctx.setLineDash([10, 15]);

        this.position.reduce((accumulator, currentValue, index) => {
          // It starts from index 1 when there is no initial value passed for reduce
          if (index === 1) {
            const calculatedAccumulator = this.calculatePosition(accumulator);
            ctx.moveTo(calculatedAccumulator.x, calculatedAccumulator.y);
          }

          const calculatedCurrent = this.calculatePosition(currentValue);
          ctx.lineTo(calculatedCurrent.x, calculatedCurrent.y);

          return currentValue;
        });

        ctx.closePath();
        ctx.strokeStyle = '#000';
        ctx.lineWidth = 5;
        ctx.stroke();
        ctx.fillStyle = '#999';
        ctx.fill();
      } else {
        // eslint-disable-next-line array-callback-return
        this.position.map(({ x, y }) => {
          const position = this.calculatePosition({ x, y });
          ctx.beginPath();

          ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI);

          ctx.closePath();
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 5;
          ctx.stroke();
          ctx.fillStyle = '#999';
          ctx.fill();
        });
      }

      // this.renderGateways(ctx);
    },
    calculatePosition(position) {
      const x = this.imageX ? map(position.x, 0, this.floor.mapMedia.metadata.width, 0, this.imageX) : position.x;
      const y = this.imageY ? map(position.y, 0, this.floor.mapMedia.metadata.height, 0, this.imageY) : position.y;

      return { x, y };
    },
    onMapClick(event) {
      console.log({
        x: Math.round(map(event.layerX, 0, event.target.clientWidth, 0, this.floor.mapMedia.metadata.width)),
        y: Math.round(map(event.layerY, 0, event.target.clientHeight, 0, this.floor.mapMedia.metadata.height)),
      });
      if (!this.isSelectingRoomPosition) return;

      this.position.push({
        x: Math.round(map(event.layerX, 0, event.target.clientWidth, 0, this.floor.mapMedia.metadata.width)),
        y: Math.round(map(event.layerY, 0, event.target.clientHeight, 0, this.floor.mapMedia.metadata.height)),
      });

      this.renderRooms();
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  position: relative;

  .map-img {
    width: 100%;
    height: 100%;
  }
  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
