var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.tours.length)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tours,"options":_vm.options,"items-per-page":15,"server-items-length":_vm.totalTours,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{attrs:{"plain":"","color":"primary","to":("/exhibitions/" + _vm.exhibitionId + "/tours/new")}},[_vm._v(" "+_vm._s(_vm.$t('tours.list.newTour'))+" ")])]},proxy:true},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","to":("/exhibitions/" + _vm.exhibitionId + "/tours/" + (item.id))}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","to":("/exhibitions/" + _vm.exhibitionId + "/tours/" + (item.id) + "/update")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteTour(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}}],null,false,1861172679)})]:[_c('v-row',{staticClass:"max-height",attrs:{"justify":"center","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('tours.list.noTour'))+" "),_c('v-btn',{attrs:{"plain":"","color":"primary","to":("/exhibitions/" + _vm.exhibitionId + "/tours/new")}},[_vm._v(" "+_vm._s(_vm.$t('tours.list.newTour'))+" ")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }