export default {
  en: {
    routes: {
      login: 'Login',
      home: 'Home',
    },
    common: {
      loading: 'Loading...',
      required: 'Required',
      create: 'Create',
      update: 'Update',
      cancel: 'Cancel',
      show: 'Show',
      delete: 'Delete',
      yes: 'Yes',
      no: 'No',
    },
    drawer: {
      loggedIn: 'Logged In',
      home: 'Home',
      login: 'Login',
      about: 'About',
      museum: 'Museum',
      rooms: 'Rooms',
      poi: 'POI',
      exhibitionsMenu: 'Exhibitions',
      exhibitions: 'Exhibitions',
      floors: 'Floors',
      devices: 'Devices',
      logout: 'Logout',
      statistics: 'Statistics',
      heatmap: 'Heatmap',
      news: 'News',
      quizzes: 'Quizzes',
      tours: 'Tours',
      exhibitionItems: 'Exhibition items',
      staticSites: 'Static sites',
    },
    login: {
      title: 'Login to Museum Admin',
      email: 'E-mail',
      password: 'Password',
      button: 'Login',
    },
    exhibitions: {
      exhibitionDetails: 'Exhibition details',
      tours: 'Tours',
      deleteConfirm: 'Do you want to delete this exhibition?',
      list: {
        noExhibition: "No exhibitions yet. Why don't you create one?",
        newExhibition: 'New exhibition',
      },
      form: {
        titleHU: 'Title (HU)',
        titleEN: 'Title (EN)',
        titleDE: 'Title (DE)',
        descriptionHU: 'Description (HU)',
        descriptionEN: 'Description (EN)',
        descriptionDE: 'Description (DE)',
        type: 'Type',
        typePermanent: 'Permanent',
        typeTemporary: 'Temporary',
        startDate: 'Start date',
        endDate: 'End date',
        coverImage: 'Cover image',
        titleRule: 'Title is required',
        descriptionRule: 'Description is required',
        coverImageRule: 'Cover image is required',
        coverImageSizeRule: 'Cover image size should be less than 20 MB',
      },
    },
    exhibitionItems: {
      exhibitionItems: 'Exhibition items',
      deleteConfirm: 'Do you want to delete this exhibition item?',
      manageMedia: 'Edit media',
      list: {
        noExhibitionItem: "No exhibition items yet. Why don't you create one?",
        newExhibitionItem: 'New exhibition item',
        drag: 'Drag',
        name: 'Name',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        isHidden: 'Is hidden',
      },
      form: {
        nameHU: 'Name (HU)',
        nameEN: 'Name (EN)',
        nameDE: 'Name (DE)',
        descriptionHU: 'Description (HU)',
        descriptionEN: 'Description (EN)',
        descriptionDE: 'Description (DE)',
        nameRule: 'Name is required',
        descriptionRule: 'Description is required',
        mediaSizeRule: 'Media file size should be less than 100 MB',
        room: 'Room',
        media: 'Media',
        isHidden: 'Is hidden',
      },
    },
    floors: {
      floorNumber: 'Floor number',
      deleteConfirm: 'Do you want to delete this floor?',
      list: {
        noFloor: "No floors yet. Why don't you create one?",
        newFloor: 'New floor',
      },
      form: {
        nameHU: 'Name (HU)',
        nameEN: 'Name (EN)',
        nameDE: 'Name (DE)',
        scaleX: 'Vertical scale (in meters)',
        scaleY: 'Horizontal scale (in meters)',
        map: 'Map',
        nameRule: 'Name is required',
        floorNumberRule: 'Floor number is required',
        mapRule: 'Map is required',
        mapSizeRule: 'Map image size should be less than 100 MB',
        scaleRule: 'Scale must be positive',
      },
      newRoom: 'New Room',
    },
    devices: {
      deleteConfirm: 'Do you want to delete this device?',
      list: {
        noDevice: "No devices yet. Why don't you create one?",
        newDevice: 'New device',
        drag: 'Drag',
        name: 'Name',
        uuid: 'UUID',
        major: 'Major',
        minor: 'Minor',
        measuredPower: 'Measured power',
        height: 'Height',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      form: {
        name: 'Name',
        uuid: 'UUID',
        major: 'Major',
        minor: 'Minor',
        measuredPower: 'Measured power',
        height: 'Height',
        nameRule: 'Name is required',
        uuidRule: 'UUID is required',
        majorRule: 'Major is required',
        minorRule: 'Minor is required',
        room: 'Room',
      },
    },
    floorSelector: {
      select: 'Select a floor',
    },
    exhibitionSelector: {
      select: 'Select an exhibition',
    },
    tours: {
      tours: 'Tours',
      deleteConfirm: 'Do you want to delete this tour?',
      list: {
        noTour: "No tours yet. Why don't you create one?",
        newTour: 'New tour',
        name: 'Name',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      form: {
        nameHU: 'Name (HU)',
        nameEN: 'Name (EN)',
        nameDE: 'Name (DE)',
        nameRule: 'Name is required',
      },
    },
    news: {
      deleteConfirm: 'Do you want to delete this news?',
      list: {
        noNews: "No news yet. Why don't you create one?",
        newNews: 'New news',
      },
      form: {
        titleHU: 'Title (HU)',
        titleEN: 'Title (EN)',
        titleDE: 'Title (DE)',
        contentHU: 'Content (HU)',
        contentEN: 'Content (EN)',
        contentDE: 'Content (DE)',
        date: 'Date',
        media: 'Media',
        titleRule: 'Title is required',
        contentRule: 'Content is required',
        mediaRule: 'Media is required',
        mediaSizeRule: 'Cover image size should be less than 20 MB',
      },
    },
    rooms: {
      rooms: 'Rooms',
      deleteConfirm: 'Do you want to delete this room?',
      list: {
        noRoom: 'No rooms yet. Why don\'t you create one?',
        newRoom: 'New room',
        name: 'Name',
        isCorridor: 'Is corridor',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      form: {
        nameHU: 'Name (HU)',
        nameEN: 'Name (EN)',
        nameDE: 'Name (DE)',
        descriptionHU: 'Description (HU)',
        descriptionEN: 'Description (EN)',
        descriptionDE: 'Description (DE)',
        map: 'Map',
        isCorridor: 'Is corridor',
        nameRule: 'Name is required',
        selectPosition: 'Select position',
      },
    },
    media: {
      unsupportedAudio: 'Your browser does not support the audio tag.',
      unsupportedVideo: 'Your browser does not support the video tag.',
      unknownMediaType: 'Unsupported media type',
    },
    quizzes: {
      deleteConfirm: 'Do you want to delete this quiz?',
      list: {
        newQuiz: 'New Quiz',
        noQuiz: "No quizzes yet. Why don't you create one?",
        name: 'Name',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      form: {
        nameHU: 'Name (HU)',
        nameEN: 'Name (EN)',
        nameDE: 'Name (DE)',
        descriptionHU: 'Description (HU)',
        descriptionEN: 'Description (EN)',
        descriptionDE: 'Description (DE)',
        coverImage: 'Cover image',
        nameRule: 'Name is required',
        mediaRule: 'Cover image is required',
        mediaSizeRule: 'Cover image size should be less than 20 MB',
      },
    },
    statistics: {
      heatmap: {
        config: 'Configuration',
        blur: 'Blur',
        radius: 'Radius',
        opacity: 'Opacity',
        min: 'Min',
        max: 'Max',
      },
    },
    pois: {
      pois: 'POIs',
      deleteConfirm: 'Do you want to delete this POI?',
      list: {
        noPoi: "No POIs yet. Why don't you create one?",
        newPoi: 'New POI',
        drag: 'Drag',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        type: 'Type',
        name: 'Name',
      },
      form: {
        type: 'Type',
      },
    },
    staticSites: {
      staticSites: 'Static Sites',
      deleteConfirm: 'Do you want to delete this static site?',
      list: {
        noStaticSite: 'No static sites yet. Why don\'t you create one?',
        newStaticSite: 'New static site',
        name: 'Name',
        slug: 'URL',
        isCorridor: 'Is corridor',
        open: 'Open',
        actions: 'Actions',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
      },
      form: {
        slug: 'URL',
        name: 'Name',
        titleHU: 'Title (HU)',
        titleEN: 'Title (EN)',
        titleDE: 'Title (DE)',
        descriptionHU: 'Description (HU)',
        descriptionEN: 'Description (EN)',
        descriptionDE: 'Description (DE)',
      },
    },
  },
  hu: {
    routes: {
      login: 'Bejelentkezés',
      home: 'Kezdőoldal',
    },
    common: {
      loading: 'Betöltés...',
      required: 'Kötelezō',
      create: 'Létrehoz',
      update: 'Módosít',
      cancel: 'Mégsem',
      show: 'Részletek',
      delete: 'Törlés',
      yes: 'Igen',
      no: 'Nem',
    },
    drawer: {
      loggedIn: 'Belépve',
      home: 'Fõoldal',
      login: 'Belépés',
      about: 'Rólunk',
      museum: 'Múzeum',
      rooms: 'Szobák',
      poi: 'POI',
      exhibitionsMenu: 'Kiállítások',
      exhibitions: 'Kezelés',
      floors: 'Emeletek',
      devices: 'Eszközok',
      logout: 'Kilépés',
      statistics: 'Statisztika',
      heatmap: 'Hőtérkép',
      news: 'Hírek',
      quizzes: 'Kvízek',
      tours: 'Túrák',
      exhibitionItems: 'Kiállítási tárgyak',
      staticSites: 'Statikus oldalak',
    },
    login: {
      title: 'Múzeum Admin Belépés',
      email: 'E-mail',
      password: 'Jelszó',
      button: 'Belépés',
    },
    exhibitions: {
      exhibitionDetails: 'Kiállítás részletek',
      tours: 'Túrák',
      deleteConfirm: 'Biztos ki akarod törölni ezt a kiállítást?',
      list: {
        noExhibition: 'Nincs még kiállítás. Miért nem hozol létre egyet?',
        newExhibition: 'Új kiállítás',
      },
      form: {
        titleHU: 'Cím (HU)',
        titleEN: 'Cím (EN)',
        titleDE: 'Cím (DE)',
        descriptionHU: 'Leírás (HU)',
        descriptionEN: 'Leírás (EN)',
        descriptionDE: 'Leírás (DE)',
        type: 'Típus',
        typePermanent: 'Állandó',
        typeTemporary: 'Ideiglenes',
        startDate: 'Kezdet',
        endDate: 'Befejezés',
        coverImage: 'Borítókép',
        titleRule: 'Cím kötelezõ',
        descriptionRule: 'Leírás kötelezõ',
        coverImageRule: 'Borítókép kötelezõ',
        coverImageSizeRule: 'Borítókép max méret 20 MB',
      },
    },
    exhibitionItems: {
      exhibitionItems: 'Kiállítási tárgyak',
      deleteConfirm: 'Biztos ki akarod törölni ezt a kiállítási tárgyat?',
      manageMedia: 'Média szerkesztése',
      list: {
        noExhibitionItem: 'Nincs még kiállítási tárgy. Miért nem hozol létre egyet?',
        newExhibitionItem: 'Új kiállítási tárgy',
        drag: 'Drag',
        name: 'Név',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
        isHidden: 'Rejtett',
      },
      form: {
        nameHU: 'Név (HU)',
        nameEN: 'Név (EN)',
        nameDE: 'Név (DE)',
        descriptionHU: 'Leírás (HU)',
        descriptionEN: 'Leírás (EN)',
        descriptionDE: 'Leírás (DE)',
        nameRule: 'Név kötelezõ',
        descriptionRule: 'Leírás kötelezõ',
        mediaSizeRule: 'Média fájl max méret 100 MB',
        room: 'Szoba',
        media: 'Média',
        isHidden: 'Rejtett (kincskeresés)',
      },
    },
    floors: {
      floorNumber: 'Emelet',
      deleteConfirm: 'Biztos ki akarod törölni ezt az emeletet?',
      list: {
        noFloor: 'Nincs még emelet. Miért nem hozol létre egyet?',
        newFloor: 'Új emelet',
      },
      form: {
        nameHU: 'Név (HU)',
        nameEN: 'Név (EN)',
        nameDE: 'Név (DE)',
        scaleX: 'Vertikális méretarány (méterben)',
        scaleY: 'Horizontális méretarány (méterben)',
        map: 'Térkép',
        nameRule: 'Név kötelezõ',
        floorNumberRule: 'Emelet kötelezõ',
        mapRule: 'Térkép kötelezõ',
        mapSizeRule: 'Térkép max méret 100 MB',
        scaleRule: 'A méretaránynak pozitívnak kell lennie',
      },
      newRoom: 'Új Szoba',
    },
    devices: {
      deleteConfirm: 'Biztos ki akarod törölni ezt az eszközt?',
      list: {
        noDevice: 'Nincs még eszköz. Miért nem hozol létre egyet?',
        newDevice: 'Új eszköz',
        drag: 'Drag',
        name: 'Név',
        uuid: 'UUID',
        major: 'Major',
        minor: 'Minor',
        measuredPower: 'Measured power',
        height: 'Magasság',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
      },
      form: {
        name: 'Név',
        uuid: 'UUID',
        major: 'Major',
        minor: 'Minor',
        measuredPower: 'Measured power',
        height: 'Magasság',
        nameRule: 'Név kötelezõ',
        uuidRule: 'UUID kötelezõ',
        majorRule: 'Major kötelezõ',
        minorRule: 'Minor kötelezõ',
        room: 'Szoba',
      },
    },
    floorSelector: {
      select: 'Emelet választása',
    },
    exhibitionSelector: {
      select: 'Kiállítás választása',
    },
    tours: {
      tours: 'Túrák',
      deleteConfirm: 'Biztos ki akarod törölni ezt az túrát?',
      list: {
        noTour: 'Nincs még túra. Miért nem hozol létre egyet?',
        newTour: 'Új túra',
        name: 'Név',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
      },
      form: {
        nameHU: 'Név (HU)',
        nameEN: 'Név (EN)',
        nameDE: 'Név (DE)',
        nameRule: 'Név kötelezõ',
      },
    },
    news: {
      deleteConfirm: 'Biztos ki akarod törölni ezt az hírt?',
      list: {
        noNews: 'Nincs még hír. Miért nem hozol létre egyet?',
        newNews: 'Új hír',
      },
      form: {
        titleHU: 'Cím (HU)',
        titleEN: 'Cím (EN)',
        titleDE: 'Cím (DE)',
        contentHU: 'Tartalom (HU)',
        contentEN: 'Tartalom (EN)',
        contentDE: 'Tartalom (DE)',
        date: 'Dátum',
        media: 'Média',
        titleRule: 'Cím kötelezõ',
        contentRule: 'Tartalom kötelezõ',
        mediaRule: 'Media kötelezõ',
        mediaSizeRule: 'Media max méret 20 MB',
      },
    },
    rooms: {
      rooms: 'Termek',
      deleteConfirm: 'Biztos ki akarod törölni ezt a termet?',
      list: {
        noRoom: 'Nincs még terem. Miért nem hozol létre egyet?',
        newRoom: 'Új terem',
        name: 'Név',
        isCorridor: 'Folyosó',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
      },
      form: {
        nameHU: 'Név (HU)',
        nameEN: 'Név (EN)',
        nameDE: 'Név (DE)',
        descriptionHU: 'Leírás (HU)',
        descriptionEN: 'Leírás (EN)',
        descriptionDE: 'Leírás (DE)',
        map: 'Térkép',
        isCorridor: 'Folyosó',
        nameRule: 'Név kötelezõ',
        selectPosition: 'Pozíció kiválasztása',
      },
    },
    media: {
      unsupportedAudio: 'A böngészője nem támogatja a hangfájlok lejátszását.',
      unsupportedVideo: 'A böngészője nem támogatja a videófájlok lejátszását.',
      unknownMediaType: 'Nem támogatott médiaformátum!',
    },
    quizzes: {
      deleteConfirm: 'Biztos ki akarod törölni ezt a kvízt?',
      list: {
        newQuiz: 'Új kvíz',
        noQuiz: 'Nincs még kvíz. Miért nem hozol létre egyet?',
        name: 'Név',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
      },
      form: {
        nameHU: 'Név (HU)',
        nameEN: 'Név (EN)',
        nameDE: 'Név (DE)',
        descriptionHU: 'Leírás (HU)',
        descriptionEN: 'Leírás (EN)',
        descriptionDE: 'Leírás (DE)',
        coverImage: 'Borítókép',
        nameRule: 'Név kötelezõ',
        mediaRule: 'Borítókép kötelezõ',
        mediaSizeRule: 'Borítókép max méret 20 MB',
      },
    },
    statistics: {
      heatmap: {
        config: 'Konfiguráció',
        blur: 'Homály',
        radius: 'Kiterjedés',
        opacity: 'Átlátszatlanság',
        min: 'Min',
        max: 'Max',
      },
    },
    pois: {
      pois: 'POI-k',
      deleteConfirm: 'Biztos ki akarod törölni ezt a POI-t?',
      list: {
        noPoi: 'Nincs még POI. Miért nem hozol létre egyet?',
        newPoi: 'Új POI',
        drag: 'Drag',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
        type: 'Típus',
        name: 'Név',

      },
      form: {
        type: 'Típus',
      },
    },
    staticSites: {
      staticSites: 'Statikus oldalak',
      deleteConfirm: 'Biztos ki akarod törölni ezt a statikus oldalt?',
      list: {
        noStaticSite: 'Nincs még statikus oldal. Miért nem hozol létre egyet?',
        newStaticSite: 'Új statikus oldal',
        name: 'Név',
        slug: 'URL',
        open: 'Megnyitás',
        actions: 'Műveletek',
        createdAt: 'Létrehozva',
        updatedAt: 'Módosítva',
      },
      form: {
        name: 'Név',
        slug: 'URL',
        titleHU: 'Cím (HU)',
        titleEN: 'Cím (EN)',
        titleDE: 'Cím (DE)',
        descriptionHU: 'Leírás (HU)',
        descriptionEN: 'Leírás (EN)',
        descriptionDE: 'Leírás (DE)',
      },
    },
  },
};
