import Vue from 'vue';
import Vuex from 'vuex';

import poi from './poi.module';
import tour from './tour.module';
import news from './news.module';
import room from './room.module';
import quiz from './quiz.module';
import theme from './theme.module';
import floor from './floor.module';
import device from './device.module';
import gateway from './gateway.module';
import authentication from './auth.module';
import exhibition from './exhibition.module';
import staticSite from './staticSite.module';
import exhibitionItem from './exhibitionItem.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    theme,
    authentication,

    poi,
    tour,
    news,
    room,
    quiz,
    floor,
    device,
    gateway,
    exhibition,
    staticSite,
    exhibitionItem,
  },
});
