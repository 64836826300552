<template>
  <v-container>

    <v-form
      ref="tourFormRef"
      v-model="valid"
      lazy-validation
    >

      <!-- Name -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="tourForm.name.hu"
            :label="$t('tours.form.nameHU')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="tourForm.name.en"
            :label="$t('tours.form.nameEN')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="tourForm.name.de"
            :label="$t('tours.form.nameDE')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import i18n from '@/i18n';
import Alert from '@/components/Alert.vue';

export default {
  name: 'TourForm',
  components: {
    Alert,
  },
  data() {
    return {
      valid: true,
      tourForm: {
        name: {
          hu: '',
          en: '',
          de: '',
        },
      },
      nameRules: [
        (v) => !!v || i18n.t('tours.form.nameRule'),
      ],
      isUpdate: false,
      exhibitionId: this.$route.params.id,
    };
  },
  async created() {
    this.$store.dispatch('tour/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('tour/getTour', {
        exhibitionId: this.$route.params.id,
        id: this.$route.params.tourId,
      });
      this.isUpdate = true;
      this.tourForm.name = this.tour.name;
    }
  },
  computed: {
    loading() {
      return this.$store.state.tour.loading;
    },
    tour() {
      return this.$store.state.tour.selectedTour;
    },
    error() {
      return this.$store.state.tour.error;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.tourFormRef.validate()) {
        const {
          name,
        } = this.tourForm;

        if (this.isUpdate) {
          const data = {
            name,
          };
          await this.$store.dispatch('tour/updateTour', {
            data,
            id: this.tour.id,
            exhibitionId: this.exhibitionId,
          });
          this.$router.push(`/exhibitions/${this.exhibitionId}/tours/${this.tour.id}`);
        } else {
          const createdTour = await this.$store.dispatch('tour/createTour', {
            exhibitionId: this.exhibitionId,
            data: { name },
          });
          this.$router.push(`/exhibitions/${this.exhibitionId}/tours/${createdTour.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('tour/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
