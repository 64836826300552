<template>
  <v-container>

    <template>
      <ExhibitionSelector :on-change="onExhibitionChange"/>

      <ExhibitionItemList
        v-if="exhibition"
        :bus="busses.exhibitionItem"
        :exhibitionId="exhibition.id"
      />

    </template>

  </v-container>
</template>

<script>
import Vue from 'vue';

import ExhibitionSelector from '@/components/ExhibitionSelector';
import ExhibitionItemList from '@/components/ExhibitionItemList';

export default {
  name: 'ExhibitionItemListPage',
  components: {
    ExhibitionSelector,
    ExhibitionItemList,
  },
  data: () => ({
    busses: {
      exhibitionItem: new Vue(),
    },
  }),
  created() {
    this.$store.dispatch('exhibition/removeSelectedExhibition');
    this.$store.dispatch('exhibition/listExhibitions');
  },
  computed: {
    exhibition() {
      return this.$store.state.exhibition.selectedExhibition;
    },
    loading() {
      return this.$store.state.floor.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onExhibitionChange(exhibition) {
      this.$store.dispatch('exhibition/getExhibition', { id: exhibition.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
