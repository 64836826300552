var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.staticSites.length)?[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.staticSites,"options":_vm.options,"items-per-page":15,"server-items-length":_vm.totalStaticSites,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{staticClass:"ma-4",attrs:{"color":"primary","to":"/static-sites/new"}},[_vm._v(" "+_vm._s(_vm.$t('staticSites.list.newStaticSite'))+" ")])]},proxy:true},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","to":("/static-sites/" + (item.id))}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","text":"","to":("/static-sites/" + (item.id) + "/update")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"icon":"","small":"","text":"","color":"error"},on:{"click":function($event){return _vm.deleteStaticSite(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}}],null,false,569905160)})]:[_c('v-row',{staticClass:"max-height",attrs:{"justify":"center","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('staticSites.list.noStaticSite'))+" "),_c('v-btn',{attrs:{"plain":"","color":"primary","to":"/static-sites/new"}},[_vm._v(" "+_vm._s(_vm.$t('staticSites.list.newStaticSite'))+" ")])],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }