var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.devices.length)?_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t('drawer.devices'))+" ")]),_c('v-expansion-panel-content',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"options":_vm.options,"items-per-page":-1,"server-items-length":_vm.totalDevices,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-btn',{attrs:{"plain":"","color":"primary","to":"/devices/new"}},[_vm._v(" "+_vm._s(_vm.$t('devices.list.newDevice'))+" ")])]},proxy:true},{key:"item.drag",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"draggable":"true"},on:{"dragstart":function($event){return _vm.startDrag($event, item)}}},[_vm._v(" mdi-drag ")])]}},{key:"item.open",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","to":("/devices/" + (item.id))}},[_c('v-icon',[_vm._v(" mdi-eye ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"icon":"","small":"","to":("/devices/" + (item.id) + "/update")}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteDevice(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdAt).toLocaleString()))])]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.updatedAt).toLocaleString()))])]}}],null,false,790464858)})],1)],1)],1):_c('v-row',{staticClass:"max-height",attrs:{"justify":"center","align":"center"}},[_vm._v(" "+_vm._s(_vm.$t('devices.list.noDevice'))+" "),_c('v-btn',{attrs:{"plain":"","color":"primary","to":"/devices/new"}},[_vm._v(" "+_vm._s(_vm.$t('devices.list.newDevice'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }