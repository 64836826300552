const dark = JSON.parse(localStorage.getItem('darkTheme'));

const initialState = {
  dark,
};

const actions = {
  toggleTheme({ commit }) {
    commit('toggleTheme');
  },
};

const mutations = {
  toggleTheme(state) {
    state.dark = !state.dark;
    localStorage.setItem('darkTheme', JSON.stringify(state.dark));
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
