/* eslint-disable no-console */

import { jsonToFormData } from '@/utils';
import { news as newsService } from '@/services';

const initialState = {
  error: '',
  news: [],
  loading: false,
  selectedNews: null,
};

const actions = {
  async listNews({ commit }) {
    commit('setLoading', true);
    try {
      const news = await newsService.listNews();
      commit('listNewsSuccess', news.data);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getNews({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const news = await newsService.getNews(id);
      commit('getNewsSuccess', news);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  // eslint-disable-next-line consistent-return
  async createNews({ commit }, data) {
    commit('setLoading', true);
    try {
      return newsService.createNews(jsonToFormData(data));
    } catch (e) {
      commit('setError', e);
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  async updateNews({ commit }, { id, data }) {
    commit('setLoading', true);
    try {
      await newsService.updateNews(id, jsonToFormData(data));
    } catch (e) {
      commit('setError', e);
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteNews({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await newsService.deleteNews(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  clearError({ commit }) {
    commit('clearError');
  },
  removeSelectedNews({ commit }) {
    commit('getNewSuccess', null);
  },
};

const mutations = {
  listNewsSuccess(state, news) {
    state.news = news;
  },
  getNewsSuccess(state, news) {
    state.selectedNews = news;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
