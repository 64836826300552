<template>
  <v-container>

    <v-form
      ref="newsForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Title -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.hu"
            :label="$t('news.form.titleHU')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.en"
            :label="$t('news.form.titleEN')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.de"
            :label="$t('news.form.titleDE')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('news.form.contentHU')}}</h3>

          <editor ref="toastuiEditorHu"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="content.hu"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('news.form.contentEN')}}</h3>

          <editor ref="toastuiEditorEn"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="content.en"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('news.form.contentDE')}}</h3>

          <editor ref="toastuiEditorDe"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="content.de"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-dialog
          ref="dateDialog"
          v-model="modal.date"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              :label="$t('news.form.date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="modal.date = false"
            >
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dateDialog.save(date)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-row>

      <v-img
        v-if="mediaPreview && !media"
        :src="mediaPreview"
        height="200px"
      >
      </v-img>

      <v-file-input
        accept=".png, .jpg, .jpeg"
        :label="$t('news.form.media')"
        :rules="isUpdate ? mediaUpdateRules : mediaRules"
        v-model="media"
      ></v-file-input>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import { DateTime } from 'luxon';
import { Editor } from '@toast-ui/vue-editor';

import i18n from '@/i18n';
import Alert from '@/components/Alert.vue';

export default {
  name: 'NewsForm',
  components: {
    Alert,
    Editor,
  },
  data() {
    return {
      valid: true,
      title: {
        hu: '',
        en: '',
        de: '',
      },
      content: {
        hu: '',
        en: '',
        de: '',
      },
      date: '',
      modal: {
        date: false,
      },
      media: null,
      mediaPreview: '',
      mediaRules: [
        (v) => !!v || i18n.t('news.form.mediaRule'),
        (v) => (!!v && v.size <= 100 * 1024 * 1024) || i18n.t('news.form.mediaSizeRule'),
      ],
      mediaUpdateRules: [
        (v) => {
          if (this.isUpdate) return true;
          return (!!v && v.size <= 100 * 1024 * 1024) || i18n.t('news.form.mediaSizeRule');
        },
      ],
      isUpdate: false,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
    };
  },
  async created() {
    this.$store.dispatch('news/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('news/getNews', { id: this.$route.params.id });
      this.isUpdate = true;
      this.title = this.news.title;
      this.content = this.news.content;
      this.mediaPreview = this.news.media.prefixedUrl;
      this.date = DateTime.fromJSDate(new Date(this.news.date)).toFormat('yyyy-MM-dd');

      this.$refs.toastuiEditorHu.invoke('setMarkdown', this.news.content.hu, true);
      this.$refs.toastuiEditorEn.invoke('setMarkdown', this.news.content.en, true);
      this.$refs.toastuiEditorDe.invoke('setMarkdown', this.news.content.de, true);
    }
  },
  computed: {
    loading() {
      return this.$store.state.news.loading;
    },
    news() {
      return this.$store.state.news.selectedNews;
    },
    error() {
      return this.$store.state.news.error;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.newsForm.validate()) {
        const {
          title, media, date,
        } = this;

        if (this.isUpdate) {
          const data = {
            date,
            title,
            content: {
              hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
              en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
              de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
            },
          };
          if (media) data.media = media;
          await this.$store.dispatch('news/updateNews', {
            id: this.news.id,
            data,
          });
          this.$router.push(`/news/${this.news.id}`);
        } else {
          const createdNews = await this.$store.dispatch('news/createNews', {
            title,
            media,
            date,
            content: {
              hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
              en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
              de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
            },
          });
          this.$router.push(`/news/${createdNews.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('news/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
