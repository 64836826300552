<template>
  <div class="text-center">
    <v-menu
      top
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-translate</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item
          v-for="(language, index) in languageOptions"
          :key="index"
          @click="changeLanguage(language)"
        >
          <v-list-item-title>{{ language.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'LanguageSelector',
  data: () => ({
    languageOptions: [
      { text: 'Magyar', value: 'hu' },
      { text: 'English', value: 'en' },
    ],
  }),
  methods: {
    changeLanguage(language) {
      this.$i18n.locale = language.value;
      localStorage.setItem('language', language.value);
    },
  },
};
</script>
