<template>
  <v-container>

    <template>
      <FloorSelector :on-change="onFloorChange"/>

      <FloorMap
        v-if="floor"
        :floor="floor"
        class="mb-4"/>

      <RoomList
        v-if="floor"
        :floor-id="floor.id"
        :rooms="floor.rooms"
        :onDeleted="onDeleted"
      />

    </template>

  </v-container>
</template>

<script>
import RoomList from '@/components/RoomList';
import FloorMap from '@/components/FloorMap';
import FloorSelector from '@/components/FloorSelector';

export default {
  name: 'FloorList',
  components: {
    RoomList,
    FloorMap,
    FloorSelector,
  },
  data: () => ({
    dialog: false,
  }),
  created() {
    this.$store.dispatch('floor/removeSelectedFloor');
    this.$store.dispatch('floor/listFloors');
  },
  computed: {
    floor() {
      return this.$store.state.floor.selectedFloor;
    },
    loading() {
      return this.$store.state.floor.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    onFloorChange(floor) {
      this.$store.dispatch('floor/getFloor', { id: floor.id });
    },
    onDeleted() {
      this.$store.dispatch('floor/getFloor', { id: this.floor.id });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
