import axios from './axios';

async function listNews() {
  const response = await axios({
    method: 'get',
    url: '/admin/news',
  });

  return response.data;
}

async function getNews(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/news/${id}`,
  });

  return response.data;
}

async function createNews(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/news',
    data,
  });

  return response.data;
}

async function deleteNews(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/news/${id}`,
  });

  return response.data;
}

async function updateNews(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/news/${id}`,
    data,
  });

  return response.data;
}

export default {
  getNews,
  listNews,
  createNews,
  deleteNews,
  updateNews,
};
