/* eslint-disable no-console */
import { poi as poisService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  pois: [],
  totalPois: 0,
  selectedPoi: null,
  loading: false,
};

const actions = {
  async listPois({ commit }, { page, itemsPerPage, floorId } = {}) {
    commit('setLoading', true);
    try {
      const pois = await poisService.listPois(floorId, page, itemsPerPage);
      commit('listPoisSuccess', {
        total: pois.meta.total,
        pois: pois.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getPoi({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const poi = await poisService.getPoi(id);
      commit('getPoisSuccess', poi);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createPoi({ commit }, data) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return poisService
      .createPoi(formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deletePoi({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await poisService.deletePoi(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updatePoi(
    { commit },
    { id, data },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return poisService
      .updatePoi(id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listPoisSuccess(state, { total, pois }) {
    state.pois = pois;
    state.totalPois = total;
  },
  getPoisSuccess(state, poi) {
    state.selectedPoi = poi;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  pois(state) {
    return state.pois;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
