<template>
  <v-expansion-panels v-if="totalPois">
    <v-expansion-panel>

      <v-expansion-panel-header>
        {{ $t('pois.pois') }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>

        <v-data-table
          :headers="headers"
          :items="pois"
          :options.sync="options"
          :items-per-page="-1"
          :server-items-length="totalPois"
          :loading="loading"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-btn color="primary" :to="`/pois/new`" class="ma-4">
              {{ $t('pois.list.newPoi') }}
            </v-btn>
          </template>

          <template v-slot:item.isHidden="{ item }">
            {{ $t(item.isHidden ? 'common.yes' : 'common.no') }}
          </template>

          <template v-slot:item.drag="{ item }">
            <v-icon draggable="true"
                    @dragstart='startDrag($event, item)'>
              mdi-drag
            </v-icon>
          </template>

          <template v-slot:item.open="{ item }">
            <v-btn icon small :to="`/pois/${item.id}`" >
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              small
              class="mr-2"
              :to="`/pois/${item.id}/update`">
              <v-icon
                small
                :to="`/pois/${item.id}/update`"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
          </template>

          <template v-slot:item.updatedAt="{ item }">
            <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
          </template>
        </v-data-table>

      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>

  <v-row v-else justify="center" align="center" class="max-height">
    {{ $t('pois.list.noPoi') }}
    <v-btn plain color="primary" :to="`/pois/new`">
      {{ $t('pois.list.newPoi') }}
    </v-btn>
  </v-row>

</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import i18n from '@/i18n';

export default {
  name: 'PoiList',
  props: {
    showDraggableColumn: Boolean,
    bus: Object,
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    getDataFromApi() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$store.dispatch('poi/listPois', {
        page,
        itemsPerPage,
      });
    },

    startDrag(event, poi) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', poi.id);
      event.dataTransfer.setData('type', 'poi');
    },

    async deleteItem({ id, name }) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('pois.deleteConfirm'),
        { color: 'red', itemName: name[this.language] },
      )) {
        await this.$store.dispatch('poi/deletePoi', { exhibitionId: this.exhibitionId, id });

        // If the removed item was the last one in the page we need to go to the previous page
        if (this.pois.length - 1 <= 0 && this.options.page > 1) {
          this.options.page -= 1;
        }
        this.$store.dispatch('poi/listPois', {
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
          exhibitionId: this.exhibitionId,
        });
      }
    },
  },
  created() {
    this.$store.dispatch('poi/listPois');
  },
  mounted() {
    if (this.bus) {
      this.bus.$on('refresh', this.getDataFromApi);
    }
  },
  computed: {
    pois() {
      return this.$store.state.poi.pois;
    },
    totalPois() {
      return this.$store.state.poi.totalPois;
    },
    loading() {
      return this.$store.state.poi.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        ...(this.showDraggableColumn ? [
          {
            text: i18n.t('pois.list.drag'),
            value: 'drag',
            sortable: false,
            width: '64px',
          },
        ] : []),
        {
          text: i18n.t('pois.list.open'),
          value: 'open',
          sortable: false,
          width: '64px',
        },
        {
          text: i18n.t('pois.list.name'),
          value: `name.${this.language}`,
          sortable: false,
        },
        {
          text: i18n.t('pois.list.type'),
          value: 'type',
          sortable: false,
        },
        {
          text: i18n.t('pois.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('pois.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('pois.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style scoped>

</style>
