<template>
  <v-container v-if="media" class="container mb-10">

    <template v-if="media.type === 'image'">
      <v-img
        contain
        :src="media.prefixedUrl"
      ></v-img>
    </template>

    <template v-else-if="media.type === 'audio'">
      <audio controls>
        <source :src="media.prefixedUrl" :type="`${media.metadata.type}/${media.metadata.subtype}`">
        {{ $t('media.unsupportedAudio')}}
      </audio>
    </template>

    <template v-else-if="media.type === 'video'">
      <video controls>
        <source :src="media.prefixedUrl" :type="`${media.metadata.type}/${media.metadata.subtype}`">
        {{ $t('media.unsupportedVideo')}}
      </video>
    </template>

    <template v-else-if="media.type === '3D'">
      <model-obj v-if="media.metadata.extname === 'obj'" :src="media.prefixedUrl"></model-obj>
      <model-stl v-else-if="media.metadata.extname === 'stl'" :src="media.prefixedUrl"></model-stl>
      <template v-else>{{ $t('media.unknownMediaType')}}</template>
    </template>

    <template v-else>
      {{ $t('media.unknownMediaType')}} <br />
      {{ media.type }}
    </template>

  </v-container>
</template>

<script>
import { ModelObj, ModelStl } from 'vue-3d-model';

export default {
  name: 'Media',
  props: {
    media: Object,
  },
  components: {
    ModelObj,
    ModelStl,
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
}
</style>
