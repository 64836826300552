const getMessageFromError = (error) => {
  const message = error.response
    ? error.response.data.errors.map((e) => {
      switch (e.rule) {
        case 'unique':
          return `${e.message} for: ${e.field}`;
        default:
          // TODO
          if (e.field) {
            return `${e.message} for: ${e.field}`;
          }
          return e.message;
      }
    }).join(', ')
    : error.message;

  return message;
};

export default {
  getMessageFromError,
};
