<template>
  <v-container>

    <v-data-table
      v-if="quizList.length"
      :headers="headers"
      :items="quizList"
      :options.sync="options"
      :items-per-page="15"
      :server-items-length="totalQuizzes"
      :loading="loading"
      class="mt-4"
    >
      <template v-slot:top>
        <v-btn color="primary" :to="`/quizzes/new`" class="ma-4">
          {{ $t('quizzes.list.newQuiz') }}
        </v-btn>
      </template>

      <template v-slot:item.open="{ item }">
        <v-btn icon small :to="`/quizzes/${item.id}`" >
          <v-icon>
            mdi-eye
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          small
          class="mr-2"
          :to="`/quizzes/${item.id}/update`">
          <v-icon
            small
          >
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-icon
          small
          @click="deleteQuiz(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:item.createdAt="{ item }">
        <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
      </template>

      <template v-slot:item.updatedAt="{ item }">
        <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
      </template>
    </v-data-table>

    <template v-else>
      <v-row justify="center" align="center" class="max-height">
        {{ $t('quizzes.list.noQuiz') }}
        <v-btn plain color="primary" to="/quizzes/new">
          {{ $t('quizzes.list.newQuiz') }}
        </v-btn>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'QuizList',
  components: {},
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    options: {},
  }),
  created() {
    this.$store.dispatch('quiz/listQuizzes');
  },
  computed: {
    quizList() {
      return this.$store.state.quiz.quizzes;
    },
    totalQuizzes() {
      return this.$store.state.quiz.totalQuizzes;
    },
    loading() {
      return this.$store.state.quiz.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('quizzes.list.open'),
          value: 'open',
          sortable: false,
        },
        {
          text: i18n.t('quizzes.list.name'),
          value: `name.${this.language}`,
          sortable: false,
        },
        {
          text: i18n.t('quizzes.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('quizzes.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('quizzes.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    getDataFromApi() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$store.dispatch('quiz/listQuizzes', {
        page,
        itemsPerPage,
      });
    },
    async deleteQuiz(quiz) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('quizzes.deleteConfirm'),
        { color: 'red', itemName: quiz.name[this.language] },
      )) {
        await this.$store.dispatch('quiz/deleteQuiz', quiz);
        this.$store.dispatch('quiz/listQuizzes');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
