/* eslint-disable no-console */

import { jsonToFormData } from '@/utils';
import { floor as floorService } from '@/services';

const initialState = {
  error: '',
  floors: [],
  loading: false,
  selectedFloor: null,
  floorWithHeatmap: null,
};

const actions = {
  async listFloors({ commit }) {
    commit('setLoading', true);
    try {
      const floors = await floorService.listFloors();
      commit('listFloorsSuccess', floors.data);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getFloor({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const floor = await floorService.getFloor(id);
      commit('getFloorSuccess', floor);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  async getFloorHeatmap({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const floor = await floorService.getFloorHeatmap(id);
      commit('getFloorHeatmapSuccess', floor);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  // eslint-disable-next-line consistent-return
  async createFloor({ commit }, data) {
    commit('setLoading', true);
    try {
      return floorService.createFloor(jsonToFormData(data));
    } catch (e) {
      commit('setError', e);
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  async updateFloor({ commit }, { id, data }) {
    commit('setLoading', true);
    try {
      await floorService.updateFloor(id, jsonToFormData(data));
    } catch (e) {
      commit('setError', e);
    } finally {
      commit('setLoading', false);
    }
  },
  async deleteFloor({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await floorService.deleteFloor(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  clearError({ commit }) {
    commit('clearError');
  },
  removeSelectedFloor({ commit }) {
    commit('getFloorSuccess', null);
  },
};

const mutations = {
  listFloorsSuccess(state, floors) {
    state.floors = floors;
  },
  getFloorSuccess(state, floor) {
    state.selectedFloor = floor;
  },
  getFloorHeatmapSuccess(state, floor) {
    state.floorWithHeatmap = floor;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
