<template>
  <div class="map-container">
    <img
      class="map-img"
      ref="floorMapImage"
      v-if="floor"
      :src="floor.map"
      @load="onLoad"
    />
    <canvas
      @drop='onDrop($event)'
      @click="onMapClick"
      @dragover.prevent
      @dragenter.prevent
      ref="canvas" class="canvas"/>

    <v-tooltip
      top
      :key="device.id"
      v-for="device in filteredDevices"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-icon
          large
          color="orange"
          class="device"
          v-on="on"
          v-bind="attrs"
          v-bind:style="{ ...calculatePositionAttributes(device.position) }"
          draggable="true"
          @dragstart='startDeviceDrag($event, device)'
        >
          mdi-access-point
        </v-icon>

      </template>
      <span>{{device.name}}</span>
    </v-tooltip>

    <template v-if="pois && pois.length">
      <v-tooltip
        top
        :key="poi.id"
        v-for="poi in pois"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-img
            class="poi"
            :src="`/${poi.type}.png`"
            v-on="on"
            v-bind="attrs"
            v-bind:style="{ ...calculatePositionAttributes(poi.position), width: '48px', height: '48px' }"
            draggable="true"
            @dragstart='startPoiDrag($event, poi)'
          />
        </template>
        <span>{{poi.type}}</span>
      </v-tooltip>
    </template>

    <v-tooltip
      top
      :key="exhibitionItem.id"
      v-for="exhibitionItem in filteredExhibitionItems"
    >
      <template v-slot:activator="{ on, attrs }">

        <v-icon
          large
          v-bind:color="exhibitionItem.isHidden ? '#000A' : 'blue'"
          class="exhibition-item"
          v-on="on"
          v-bind="attrs"
          v-bind:style="{ ...calculatePositionAttributes(exhibitionItem.position) }"
          draggable="true"
          @dragstart='startExhibitionItemDrag($event, exhibitionItem)'
        >
          mdi-information
        </v-icon>

      </template>
      <span>
        {{exhibitionItem.name[language]}}{{
          exhibitionItem.isHidden ? ` (${ $t('exhibitionItems.list.isHidden') })` : ''
        }}
      </span>
    </v-tooltip>
  </div>
</template>

<script>
/* eslint-disable no-console, no-param-reassign */

import { map } from '@/utils';

const updateMapType = {
  exhibitionItem: 'exhibitionItem/updateExhibitionItem',
  device: 'device/updateDevice',
  poi: 'poi/updatePoi',
};

export default {
  name: 'FloorMap',
  props: {
    floor: Object,
    exhibitionId: String,
    devices: {
      type: Array,
      default: () => [],
    },
    exhibitionItems: {
      type: Array,
      default: () => [],
    },
    pois: {
      type: Array,
      default: () => [],
    },
    onDropEvent: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      imageX: 0,
      imageY: 0,
      // ro: null,
    };
  },
  methods: {
    async onDrop(event) {
      const id = event.dataTransfer.getData('id');
      const exhibitionId = event.dataTransfer.getData('exhibitionId');
      const itemType = event.dataTransfer.getData('type');

      const { left, top } = this.$refs.canvas.getBoundingClientRect();
      const updateParams = {
        id,
        data: {
          position: {
            x: map(event.x - left, 0, this.$refs.canvas.clientWidth, 0, this.floor.mapMedia.metadata.width),
            y: map(event.y - top, 0, this.$refs.canvas.clientHeight, 0, this.floor.mapMedia.metadata.height),
          },
          floorId: this.floor.id,
          exhibitionId: this.exhibitionId,
        },
      };

      if (exhibitionId) {
        updateParams.exhibitionId = exhibitionId;
      }

      await this.$store.dispatch(updateMapType[itemType], updateParams);
      this.onDropEvent(itemType);
    },

    startExhibitionItemDrag(event, exhibitionItem) {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', exhibitionItem.id);
      event.dataTransfer.setData('type', 'exhibitionItem');
      event.dataTransfer.setData('exhibitionId', exhibitionItem.exhibitionId);
    },

    startDeviceDrag: (event, device) => {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', device.id);
      event.dataTransfer.setData('type', 'device');
    },

    startPoiDrag: (event, poi) => {
      event.dataTransfer.dropEffect = 'move';
      event.dataTransfer.effectAllowed = 'move';
      event.dataTransfer.setData('id', poi.id);
      event.dataTransfer.setData('type', 'poi');
    },

    calculatePosition(position) {
      const x = this.imageX ? map(position.x, 0, this.floor.mapMedia.metadata.width, 0, this.imageX) : position.x;
      const y = this.imageY ? map(position.y, 0, this.floor.mapMedia.metadata.height, 0, this.imageY) : position.y;

      return { x, y };
    },

    calculatePositionAttributes(position) {
      const { x, y } = this.calculatePosition(position);

      return { left: `${x - 18}px`, top: `${y - 18}px` };
    },

    renderGateways(ctx) {
      this.gateways.forEach((gateway) => {
        if (gateway.sourceFloorId !== gateway.targetFloorId) {
          if (gateway.sourceFloorId === this.floor.id) {
            const position = this.calculatePosition(gateway.sourcePosition);
            ctx.strokeStyle = '#000';
            ctx.lineWidth = 3;
            ctx.fillStyle = '#922';

            ctx.beginPath();
            ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
          } else if (gateway.targetFloorId === this.floor.id) {
            const position = this.calculatePosition(gateway.targetPosition);
            ctx.strokeStyle = '#000';
            ctx.lineWidth = 3;
            ctx.fillStyle = '#955';

            ctx.beginPath();
            ctx.arc(position.x, position.y, 5, 0, 2 * Math.PI);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
          }
        } else if (gateway.sourceFloorId === this.floor.id) {
          const sourcePosition = this.calculatePosition(gateway.sourcePosition);
          const targetPosition = this.calculatePosition(gateway.targetPosition);
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 3;
          ctx.fillStyle = '#922';

          // Source
          ctx.beginPath();
          ctx.arc(sourcePosition.x, sourcePosition.y, 5, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

          // Target
          ctx.beginPath();
          ctx.arc(targetPosition.x, targetPosition.y, 5, 0, 2 * Math.PI);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

          // Line between
          ctx.beginPath();
          ctx.moveTo(sourcePosition.x, sourcePosition.y);
          ctx.lineTo(targetPosition.x, targetPosition.y);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();
        }
      });
    },

    onMapClick(event) {
      console.log({
        x: Math.round(map(event.layerX, 0, event.target.clientWidth, 0, this.floor.mapMedia.metadata.width)),
        y: Math.round(map(event.layerY, 0, event.target.clientHeight, 0, this.floor.mapMedia.metadata.height)),
      });
    },

    onLoad() {
      if (this.$refs.floorMapImage) {
        const imgElement = this.$refs.floorMapImage;
        this.imageX = imgElement.clientWidth;
        this.imageY = imgElement.clientHeight;
      }
      if (this.$refs.canvas && this.floor && this.floor.rooms.length) {
        const { canvas } = this.$refs;
        canvas.width = this.imageX;
        canvas.height = this.imageY;
        const ctx = canvas.getContext('2d');

        ctx.clearRect(0, 0, this.imageX, this.imageY);

        this.floor.rooms.forEach((room) => {
          ctx.beginPath();
          room.position.reduce((accumulator, currentValue, index) => {
            // It starts from index 1 when there is no initial value passed for reduce
            if (index === 1) {
              const calculatedAccumulator = this.calculatePosition(accumulator);
              ctx.moveTo(calculatedAccumulator.x, calculatedAccumulator.y);
            }

            const calculatedCurrent = this.calculatePosition(currentValue);
            ctx.lineTo(calculatedCurrent.x, calculatedCurrent.y);

            return currentValue;
          });

          ctx.closePath();
          ctx.strokeStyle = '#000';
          ctx.lineWidth = 5;
          ctx.stroke();
          ctx.fillStyle = '#999D'; // A bit transparent
          ctx.fill();

          const textPosition = this.calculatePosition({
            x: room.centroid.x || (room.position[0].x + room.position[2].x) / 2,
            y: room.centroid.y || (room.position[0].y + room.position[2].y) / 2,
          });

          ctx.beginPath();
          ctx.font = '24px Arial';
          ctx.fillStyle = 'black';
          ctx.textAlign = 'center'; // Horizontal alignment
          ctx.textBaseline = 'middle'; // Vertical alignment
          ctx.fillText(room.name[this.language], textPosition.x, textPosition.y);
          ctx.closePath();
        });
        this.renderGateways(ctx);
      }
    },
  },

  computed: {
    language() {
      return this.$i18n.locale;
    },
    filteredExhibitionItems() {
      return this.exhibitionItems
        .filter(({ position, floorId }) => this.floor && floorId === this.floor.id && position);
    },
    filteredDevices() {
      return this.devices
        .filter(({ position, floorId }) => this.floor && floorId === this.floor.id && position);
    },
    gateways() {
      return this.$store.state.gateway.gateways;
    },
  },

  mounted() {
    window.addEventListener('resize', this.onLoad);
    this.onLoad();
    // TODO
    // this.ro = new ResizeObserver(this.onLoad);
    // this.ro.observe(this.$refs.floorMapImage);
  },

  beforeDestroy() {
    // this.ro.unobserve(this.$refs.floorMapImage);
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  position: relative;

  .device, .exhibition-item, .poi {
    position: absolute;
  }

  .map-img {
    width: 100%;
    height: 100%;
  }
  .canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
