import axios from './axios';

async function listExhibitionItems(exhibitionId, page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items`,
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function getExhibitionItem(exhibitionId, id) {
  const response = await axios({
    method: 'get',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items/${id}`,
  });

  return response.data;
}

async function createExhibitionItem(exhibitionId, data) {
  const response = await axios({
    method: 'post',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items`,
    data,
  });

  return response.data;
}

async function deleteExhibitionItem(exhibitionId, id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items/${id}`,
  });

  return response.data;
}

async function updateExhibitionItem(exhibitionId, id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items/${id}`,
    data,
  });

  return response.data;
}

async function updateExhibitionItemMedia(exhibitionId, id, media) {
  const response = await axios({
    method: 'put',
    url: `/admin/exhibitions/${exhibitionId}/exhibition-items/${id}/media`,
    data: { media },
  });

  return response.data;
}

export default {
  listExhibitionItems,
  getExhibitionItem,
  createExhibitionItem,
  deleteExhibitionItem,
  updateExhibitionItem,
  updateExhibitionItemMedia,
};
