var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"map-container"},[(_vm.floor)?_c('img',{ref:"floorMapImage",staticClass:"map-img",attrs:{"src":_vm.floor.map},on:{"load":_vm.onLoad}}):_vm._e(),_c('canvas',{ref:"canvas",staticClass:"canvas",on:{"drop":function($event){return _vm.onDrop($event)},"click":_vm.onMapClick,"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}}),_vm._l((_vm.filteredDevices),function(device){return _c('v-tooltip',{key:device.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"device",style:(Object.assign({}, _vm.calculatePositionAttributes(device.position))),attrs:{"large":"","color":"orange","draggable":"true"},on:{"dragstart":function($event){return _vm.startDeviceDrag($event, device)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-access-point ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(device.name))])])}),(_vm.pois && _vm.pois.length)?_vm._l((_vm.pois),function(poi){return _c('v-tooltip',{key:poi.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-img',_vm._g(_vm._b({staticClass:"poi",style:(Object.assign({}, _vm.calculatePositionAttributes(poi.position), {width: '48px', height: '48px'})),attrs:{"src":("/" + (poi.type) + ".png"),"draggable":"true"},on:{"dragstart":function($event){return _vm.startPoiDrag($event, poi)}}},'v-img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(_vm._s(poi.type))])])}):_vm._e(),_vm._l((_vm.filteredExhibitionItems),function(exhibitionItem){return _c('v-tooltip',{key:exhibitionItem.id,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"exhibition-item",style:(Object.assign({}, _vm.calculatePositionAttributes(exhibitionItem.position))),attrs:{"large":"","color":exhibitionItem.isHidden ? '#000A' : 'blue',"draggable":"true"},on:{"dragstart":function($event){return _vm.startExhibitionItemDrag($event, exhibitionItem)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(exhibitionItem.name[_vm.language])+_vm._s(exhibitionItem.isHidden ? (" (" + (_vm.$t('exhibitionItems.list.isHidden')) + ")") : '')+" ")])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }