<template>
  <v-container>

    <v-form
      ref="deviceForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Name & UUID -->
      <v-row>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="name"
            :label="$t('devices.form.name')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="uuid"
            :label="$t('devices.form.uuid')"
            :rules="uuidRules"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Major, Minor and Measured power -->
      <v-row>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            type="number"
            v-model="major"
            :label="$t('devices.form.major')"
            :rules="majorRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            type="number"
            v-model="minor"
            :label="$t('devices.form.minor')"
            :rules="minorRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            type="number"
            v-model="measuredPower"
            suffix="dBm"
            :label="$t('devices.form.measuredPower')"
            required
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            type="number"
            v-model="position.z"
            suffix="cm"
            :label="$t('devices.form.height')"
            required
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select
            clearable
            :items="rooms"
            :item-value="'id'"
            :item-text="`name.${language}`"
            :loading="loadingRooms"
            :label="$t('devices.form.room')"
            v-model="roomId"
          ></v-select>
        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import i18n from '@/i18n';
import Alert from '@/components/Alert.vue';

export default {
  name: 'DeviceForm',
  components: {
    Alert,
  },
  data() {
    return {
      valid: true,
      name: '',
      uuid: '',
      major: null,
      minor: null,
      measuredPower: null,
      position: { z: 350 },
      roomId: null,
      nameRules: [
        (v) => !!v || i18n.t('devices.form.nameRule'),
      ],
      uuidRules: [
        (v) => !!v || i18n.t('devices.form.uuidRule'),
      ],
      majorRules: [
        (v) => (v && v >= 0) || i18n.t('devices.form.majorRule'),
      ],
      minorRules: [
        (v) => (v && v >= 0) || i18n.t('devices.form.minorRule'),
      ],
      isUpdate: false,
    };
  },
  async created() {
    this.$store.dispatch('device/clearError');
    this.$store.dispatch('room/listAllRooms', {});
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('device/getDevice', { id: this.$route.params.id });
      this.isUpdate = true;
      this.name = this.device.name;
      this.uuid = this.device.uuid;
      this.major = this.device.major;
      this.minor = this.device.minor;
      this.roomId = this.device.roomId;
      this.measuredPower = this.device.measuredPower;
    }
  },
  computed: {
    loading() {
      return this.$store.state.device.loading;
    },
    device() {
      return this.$store.state.device.selectedDevice;
    },
    error() {
      return this.$store.state.device.error;
    },
    rooms() {
      return this.$store.state.room.rooms;
    },
    loadingRooms() {
      return this.$store.state.room.loading;
    },
    language() {
      return this.$i18n.locale;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.deviceForm.validate()) {
        const {
          name, uuid, major, minor, measuredPower, roomId,
        } = this;

        if (this.isUpdate) {
          const data = {
            name, uuid, major: parseInt(major, 10), minor: parseInt(minor, 10), measuredPower, roomId,
          };
          await this.$store.dispatch('device/updateDevice', {
            id: this.device.id,
            data,
          });
          this.$router.push(`/devices/${this.device.id}`);
        } else {
          const createdDevice = await this.$store.dispatch('device/createDevice', {
            name, uuid, major: parseInt(major, 10), minor: parseInt(minor, 10), measuredPower, roomId,
          });
          this.$router.push(`/devices/${createdDevice.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('device/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
