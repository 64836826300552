/* eslint-disable no-console */
import { gateway as gatewaysService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  gateways: [],
  totalGateways: 0,
  selectedGateway: null,
  loading: false,
};

const actions = {
  async listGateways({ commit }, { page, itemsPerPage, floorId } = {}) {
    commit('setLoading', true);
    try {
      const gateways = await gatewaysService.listGateways(floorId, page, itemsPerPage);
      commit('listGatewaysSuccess', {
        total: gateways.meta.total,
        gateways: gateways.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getGateway({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const gateway = await gatewaysService.getGateway(id);
      commit('getGatewaysSuccess', gateway);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createGateway({ commit }, data) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return gatewaysService
      .createGateway(formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteGateway({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await gatewaysService.deleteGateway(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateGateway(
    { commit },
    { id, data },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return gatewaysService
      .updateGateway(id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listGatewaysSuccess(state, { total, gateways }) {
    state.gateways = gateways;
    state.totalGateways = total;
  },
  getGatewaysSuccess(state, gateway) {
    state.selectedGateway = gateway;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  gateways(state) {
    return state.gateways;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
