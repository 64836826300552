/* eslint-disable no-console */

import router from '@/router';

import { exhibition as exhibitionService } from '@/services';

const initialState = {
  error: '',
  exhibitions: [],
  selectedExhibition: null,
  loading: false,
};

const createExhibitionFormData = (data) => {
  const formData = new FormData();
  formData.append('title.hu', data.title.hu);
  formData.append('title.en', data.title.en);
  formData.append('title.de', data.title.de);
  formData.append('description.hu', data.description.hu);
  formData.append('description.de', data.description.de);
  formData.append('description.en', data.description.en);
  formData.append('type', data.type);
  formData.append('startDate', data.startDate);
  formData.append('endDate', data.endDate);
  if (data.coverImage) formData.append('coverImage', data.coverImage);
  return formData;
};

const actions = {
  async listExhibitions({ commit }) {
    commit('setLoading', true);
    try {
      const exhibitions = await exhibitionService.listExhibitions();
      commit('listExhibitionsSuccess', exhibitions.data);
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getExhibition({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const exhibition = await exhibitionService.getExhibition(id);
      commit('getExhibitionSuccess', exhibition);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createExhibition({ commit }, {
    title, description, type, startDate, endDate, coverImage,
  }) {
    commit('setLoading', true);
    const formData = createExhibitionFormData({
      title, description, type, startDate, endDate, coverImage,
    });
    exhibitionService
      .createExhibition(formData)
      .then((createdExhibition) => router.push(`/exhibitions/${createdExhibition.id}`))
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteExhibition({ commit, dispatch }, { id }) {
    commit('setLoading', true);
    try {
      await exhibitionService.deleteExhibition(id);
      await dispatch('listExhibitions');
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateExhibition(
    { commit },
    { id, data },
  ) {
    commit('setLoading', true);
    const formData = createExhibitionFormData(data);
    exhibitionService
      .updateExhibition(id, formData)
      .then(() => router.push(`/exhibitions/${id}`))
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
  removeSelectedExhibition({ commit }) {
    commit('getExhibitionSuccess', null);
  },
};

const mutations = {
  listExhibitionsSuccess(state, exhibitions) {
    state.exhibitions = exhibitions;
  },
  getExhibitionSuccess(state, exhibition) {
    state.selectedExhibition = exhibition;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  exhibitions(state) {
    return state.exhibitions;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
