import Vue from 'vue';
import VueRouter from 'vue-router';

import { auth } from '../services';

import AuthLayout from '../layouts/Auth';
import AdminLayout from '../layouts/Admin';
import i18n from '../i18n';

import Login from '../views/auth/Login';
import Home from '@/views/Home';

// Floors
import FloorList from '@/views/floors/list';
import FloorForm from '@/views/floors/form';
import ShowFloor from '@/views/floors/show';

// Rooms
import RoomList from '@/views/rooms/list';
import RoomForm from '@/views/rooms/form';
import ShowRoom from '@/views/rooms/show';

// Devices
import DeviceList from '@/views/devices/list';
import DeviceForm from '@/views/devices/form';
import ShowDevice from '@/views/devices/show';

// POIs
import PoiList from '@/views/pois/list';

// News
import NewsList from '@/views/news/list';
import NewsForm from '@/views/news/form';
import ShowNews from '@/views/news/show';

// Static sites
import StaticSiteList from '@/views/staticSites/list';
import StaticSiteForm from '@/views/staticSites/form';
import ShowStaticSite from '@/views/staticSites/show';

// Exhibitions
import ExhibitionList from '@/views/exhibitions/list';
import ExhibitionForm from '@/views/exhibitions/form';
import ShowExhibition from '@/views/exhibitions/show';

// Exhibition items
import ExhibitionItemForm from '@/views/exhibitionItems/form';
import ShowExhibitionItem from '@/views/exhibitionItems/show';
import ExhibitionItemList from '@/views/exhibitionItems/list';

// Tours
import TourList from '@/views/tours/list';
import TourListPage from '@/views/tours/list2';
import TourForm from '@/views/tours/form';
import ShowTour from '@/views/tours/show';

// Quizzes
import QuizList from '@/views/quizzes/list';
import QuizForm from '@/views/quizzes/form';
import ShowQuiz from '@/views/quizzes/show';

// Statistics
import Heatmap from '@/views/statistics/Heatmap';

Vue.use(VueRouter);

const routes = [
  {
    path: '',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          title: i18n.t('routes.login'),
        },
      },
    ],
  },
  {
    path: '',
    component: AdminLayout,
    meta: {
      title: i18n.t('routes.home'),
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },

      // Floors
      {
        path: '/floors',
        name: 'FloorList',
        component: FloorList,
      },
      {
        path: '/floors/new',
        name: 'NewFloor',
        component: FloorForm,
      },
      {
        path: '/floors/:id',
        name: 'ShowFloor',
        component: ShowFloor,
      },
      {
        path: '/floors/:id/update',
        name: 'UpdateFloor',
        component: FloorForm,
      },

      // Rooms
      {
        path: '/rooms',
        name: 'RoomList',
        component: RoomList,
      },
      {
        path: '/floors/:floorId/rooms/new',
        name: 'RoomForm',
        component: RoomForm,
      },
      {
        path: '/floors/:floorId/rooms/:id',
        name: 'ShowRoom',
        component: ShowRoom,
      },
      {
        path: '/floors/:floorId/rooms/:id/update',
        name: 'RoomUpdate',
        component: RoomForm,
      },

      // Devices
      {
        path: '/devices',
        name: 'DeviceList',
        component: DeviceList,
      },
      {
        path: '/devices/new',
        name: 'NewDevice',
        component: DeviceForm,
      },
      {
        path: '/devices/:id',
        name: 'ShowDevice',
        component: ShowDevice,
      },
      {
        path: '/devices/:id/update',
        name: 'UpdateDevice',
        component: DeviceForm,
      },

      // POIs
      {
        path: '/pois',
        name: 'PoiList',
        component: PoiList,
      },

      // News
      {
        path: '/news',
        name: 'NewsList',
        component: NewsList,
      },
      {
        path: '/news/new',
        name: 'NewsForm',
        component: NewsForm,
      },
      {
        path: '/news/:id',
        name: 'ShowNews',
        component: ShowNews,
      },
      {
        path: '/news/:id/update',
        name: 'UpdateNews',
        component: NewsForm,
      },

      // Static sites
      {
        path: '/static-sites',
        name: 'StaticSiteList',
        component: StaticSiteList,
      },
      {
        path: '/static-sites/new',
        name: 'StaticSiteForm',
        component: StaticSiteForm,
      },
      {
        path: '/static-sites/:id',
        name: 'ShowStaticSite',
        component: ShowStaticSite,
      },
      {
        path: '/static-sites/:id/update',
        name: 'UpdateStaticSite',
        component: StaticSiteForm,
      },

      // Exhibitions
      {
        path: '/exhibitions',
        name: 'ExhibitionList',
        component: ExhibitionList,
      },
      {
        path: '/exhibitions/new',
        name: 'NewExhibition',
        component: ExhibitionForm,
      },
      {
        path: '/exhibitions/:id',
        name: 'ShowExhibition',
        component: ShowExhibition,
      },
      {
        path: '/exhibitions/:id/update',
        name: 'UpdateExhibition',
        component: ExhibitionForm,
      },

      // Exhibition items
      {
        path: '/exhibition-items',
        name: 'ExhibitionItemList',
        component: ExhibitionItemList,
      },
      {
        path: '/exhibitions/:id/exhibition-items/new',
        name: 'NewExhibitionItem',
        component: ExhibitionItemForm,
      },
      {
        path: '/exhibitions/:id/exhibition-items/:exhibitionItemId/update',
        name: 'UpdateExhibitionItem',
        component: ExhibitionItemForm,
      },
      {
        path: '/exhibitions/:id/exhibition-items/:exhibitionItemId',
        name: 'ShowExhibitionItem',
        component: ShowExhibitionItem,
      },

      // Tours
      {
        path: '/tours',
        name: 'TourListPage',
        component: TourListPage,
      },
      {
        path: '/exhibitions/:id/tours',
        name: 'ListTours',
        component: TourList,
      },
      {
        path: '/exhibitions/:id/tours/new',
        name: 'NewTour',
        component: TourForm,
      },
      {
        path: '/exhibitions/:id/tours/:tourId/update',
        name: 'UpdateTour',
        component: TourForm,
      },
      {
        path: '/exhibitions/:id/tours/:tourId',
        name: 'ShowTour',
        component: ShowTour,
      },

      // Quizzes
      {
        path: '/quizzes',
        name: 'QuizList',
        component: QuizList,
      },
      {
        path: '/quizzes/new',
        name: 'QuizForm',
        component: QuizForm,
      },
      {
        path: '/quizzes/:id',
        name: 'ShowQuiz',
        component: ShowQuiz,
      },
      {
        path: '/quizzes/:id/update',
        name: 'UpdateQuiz',
        component: QuizForm,
      },

      // Statistics
      {
        path: '/heatmap',
        name: 'Heatmap',
        component: Heatmap,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const unAuthedRoutes = ['Login'];

router.beforeEach((to, from, next) => {
  if (!unAuthedRoutes.includes(to.name) && !auth.isAuthenticated()) next({ name: 'Login' });
  else next();
});

export default router;
