<template>
    <v-expansion-panels v-if="tours">
      <v-expansion-panel>
        <v-expansion-panel-header>
          {{ $t('tours.tours') }}
        </v-expansion-panel-header>

        <v-expansion-panel-content>

          <v-data-table
            :headers="headers"
            :items="tours"
            :options.sync="options"
            :items-per-page="15"
            :server-items-length="totalTours"
            :loading="loading"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-btn color="primary" :to="`/exhibitions/${exhibitionId}/tours/new`" class="ma-4">
                {{ $t('tours.list.newTour') }}
              </v-btn>
            </template>

            <template v-slot:item.open="{ item }">
              <v-btn icon small :to="`/exhibitions/${exhibitionId}/tours/${item.id}`" >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn
                icon
                small
                class="mr-2"
                :to="`/exhibitions/${exhibitionId}/tours/${item.id}/update`">
                <v-icon
                  small
                >
                  mdi-pencil
                </v-icon>
              </v-btn>
              <v-icon
                small
                @click="deleteTour(item)"
              >
                mdi-delete
              </v-icon>
            </template>

            <template v-slot:item.createdAt="{ item }">
              <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
            </template>

            <template v-slot:item.updatedAt="{ item }">
              <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
            </template>
          </v-data-table>

        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-row v-else justify="center" align="center" class="max-height">
      {{ $t('tours.list.noTour') }}
      <v-btn plain color="primary" :to="`/exhibitions/${exhibitionId}/tours/new`">
        {{ $t('tours.list.newTour') }}
      </v-btn>
    </v-row>

</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'TourList',
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  props: {
    exhibitionId: String,
  },
  data() {
    return {
      options: {},
    };
  },
  created() {
    this.getDataFromApi();
  },
  computed: {
    tours() {
      return this.$store.state.tour.tours;
    },
    totalTours() {
      return this.$store.state.tour.totalTours;
    },
    loading() {
      return this.$store.state.tour.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('tours.list.open'),
          value: 'open',
          sortable: false,
        },
        {
          text: i18n.t('tours.list.name'),
          value: `name.${this.language}`,
          sortable: false,
        },
        {
          text: i18n.t('tours.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('tours.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('tours.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    getDataFromApi() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$store.dispatch('tour/listTours', {
        page,
        itemsPerPage,
        exhibitionId: this.exhibitionId,
      });
    },

    async deleteTour({ id, name }) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('tours.deleteConfirm'),
        { color: 'red', itemName: name[this.language] },
      )) {
        await this.$store.dispatch('tour/deleteTour', {
          id,
          exhibitionId: this.exhibitionId,
        });

        // If the removed item was the last one in the page we need to go to the previous page
        if (this.tours.length - 1 <= 0 && this.options.page > 1) {
          this.options.page -= 1;
        }

        this.getDataFromApi();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
