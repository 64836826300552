<template>
  <v-container>

    <v-form
      ref="staticSiteForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Title -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="name"
            :label="$t('staticSites.form.name')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.hu"
            :label="$t('staticSites.form.titleHU')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.en"
            :label="$t('staticSites.form.titleEN')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="title.de"
            :label="$t('staticSites.form.titleDE')"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-expansion-panels class="mb-4" :value="0">
            <v-expansion-panel>

              <v-expansion-panel-header>
                {{$t('staticSites.form.descriptionHU')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorHu"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.hu"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('staticSites.form.descriptionEN')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorEn"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.en"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                {{$t('staticSites.form.descriptionDE')}}
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <editor ref="toastuiEditorDe"
                        v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                        :initialValue="description.de"
                        :initialEditType="'markdown'"
                        :height="'800px'"
                        :options="editorOptions"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';

import Alert from '@/components/Alert.vue';

export default {
  name: 'StaticSiteForm',
  components: {
    Alert,
    Editor,
  },
  data() {
    return {
      valid: true,
      name: '',
      title: {
        hu: '',
        en: '',
        de: '',
      },
      description: {
        hu: '',
        en: '',
        de: '',
      },
      isUpdate: false,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
    };
  },
  async created() {
    this.$store.dispatch('staticSite/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('staticSite/getStaticSite', { id: this.$route.params.id });
      this.isUpdate = true;
      this.name = this.staticSite.name;
      this.title = this.staticSite.title;
      this.description = this.staticSite.description;

      if (this.$refs.toastuiEditorHu) {
        this.$refs.toastuiEditorHu.invoke('setMarkdown', this.description.hu ?? '', true);
      }
      if (this.$refs.toastuiEditorEn) {
        this.$refs.toastuiEditorEn.invoke('setMarkdown', this.description.en ?? '', true);
      }
      if (this.$refs.toastuiEditorDe) {
        this.$refs.toastuiEditorDe.invoke('setMarkdown', this.description.de ?? '', true);
      }

      this.slug = this.staticSite.slug;
    }
  },
  computed: {
    loading() {
      return this.$store.state.staticSite.loading;
    },
    staticSite() {
      return this.$store.state.staticSite.selectedStaticSite;
    },
    error() {
      return this.$store.state.staticSite.error;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();

      if (this.$refs.staticSiteForm.validate()) {
        const {
          name, title,
        } = this;

        if (this.isUpdate) {
          const data = {
            name,
            title,
            description: {
              hu: this.$refs.toastuiEditorHu ? this.$refs.toastuiEditorHu.invoke('getMarkdown') : this.description.hu,
              en: this.$refs.toastuiEditorEn ? this.$refs.toastuiEditorEn.invoke('getMarkdown') : this.description.en,
              de: this.$refs.toastuiEditorDe ? this.$refs.toastuiEditorDe.invoke('getMarkdown') : this.description.de,
            },
          };
          await this.$store.dispatch('staticSite/updateStaticSite', {
            id: this.staticSite.id,
            data,
          });
          this.$router.push(`/static-sites/${this.staticSite.id}`);
        } else {
          const createdStaticSite = await this.$store.dispatch('staticSite/createStaticSite', {
            data: {
              name,
              title,
              description: {
                hu: this.$refs.toastuiEditorHu ? this.$refs.toastuiEditorHu.invoke('getMarkdown') : this.description.hu,
                en: this.$refs.toastuiEditorEn ? this.$refs.toastuiEditorEn.invoke('getMarkdown') : this.description.en,
                de: this.$refs.toastuiEditorDe ? this.$refs.toastuiEditorDe.invoke('getMarkdown') : this.description.de,
              },
            },
          });
          this.$router.push(`/static-sites/${createdStaticSite.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('staticSite/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
