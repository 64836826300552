/* eslint-disable no-console */
import { exhibitionItem as exhibitionItemService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  exhibitionItems: [],
  totalExhibitionItems: 0,
  selectedExhibitionItem: null,
  loading: false,
};

const actions = {
  async listExhibitionItems({ commit }, { exhibitionId, page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const exhibitionItems = await exhibitionItemService.listExhibitionItems(exhibitionId, page, itemsPerPage);
      commit('listExhibitionItemsSuccess', {
        total: exhibitionItems.meta.total,
        exhibitionItems: exhibitionItems.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getExhibitionItem({ commit }, { exhibitionId, id }) {
    commit('setLoading', true);
    try {
      const exhibitionItem = await exhibitionItemService.getExhibitionItem(exhibitionId, id);
      commit('getExhibitionItemSuccess', exhibitionItem);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createExhibitionItem({ commit }, {
    data, exhibitionId,
  }) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return exhibitionItemService
      .createExhibitionItem(exhibitionId, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteExhibitionItem({ commit }, { exhibitionId, id }) {
    commit('setLoading', true);
    try {
      await exhibitionItemService.deleteExhibitionItem(exhibitionId, id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateExhibitionItem(
    { commit },
    {
      id, data, exhibitionId,
    },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return exhibitionItemService
      .updateExhibitionItem(exhibitionId, id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  updateTourExhibitionItems(
    { commit },
    {
      id, media, exhibitionId,
    },
  ) {
    commit('setLoading', true);
    return exhibitionItemService
      .updateExhibitionItemMedia(exhibitionId, id, media)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listExhibitionItemsSuccess(state, { exhibitionItems, total }) {
    state.exhibitionItems = exhibitionItems;
    state.totalExhibitionItems = total;
  },
  getExhibitionItemSuccess(state, exhibitionItem) {
    state.selectedExhibitionItem = exhibitionItem;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  exhibitionItems(state) {
    return state.exhibitionItems;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
