<template>
  <v-form ref="loginForm" v-model="valid" @submit="submit" lazy-validation>
    <Alert
      v-if="error"
      dismissable
      elevation="5"
      type="error"
      :message="error"
      :onClose="resetError"
    />
    <v-text-field
      required
      v-model="email"
      :rules="emailRules"
      :label="$t('login.email')"
    ></v-text-field>

    <v-text-field
      v-model="password"
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      :rules="[rules.required]"
      :type="show ? 'text' : 'password'"
      name="input-10-1"
      :label="$t('login.password')"
      counter
      @click:append="show = !show"
    ></v-text-field>

    <div class="text-center">
      <v-btn
        x-large block
        :disabled="!valid || loggingIn" color="primary"
        type="submit"
        @click="submit"
      >{{ $t('login.button') }}</v-btn>
    </div>
  </v-form>
</template>

<script>
import Alert from '../../components/Alert.vue';
import i18n from '../../i18n';

export default {
  name: 'Login',
  components: {
    Alert,
  },
  computed: {
    error() {
      return this.$store.state.authentication.status.error;
    },
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  methods: {
    submit(event) {
      event.preventDefault();

      if (this.$refs.loginForm.validate()) {
        const { email, password } = this;
        const { dispatch } = this.$store;
        dispatch('authentication/login', { email, password });
      }

      return false;
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetError() {
      this.$store.dispatch('authentication/clearError');
    },
  },
  data: () => ({
    valid: true,
    password: '',
    email: '',
    emailRules: [
      (v) => !!v || i18n.t('common.required'),
    ],

    show: false,
    rules: {
      required: (value) => !!value || i18n.t('common.required'),
    },
  }),
};
</script>
