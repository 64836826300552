import axios from './axios';

async function listExhibitions() {
  const response = await axios({
    method: 'get',
    url: '/admin/exhibitions',
  });

  return response.data;
}

async function getExhibition(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/exhibitions/${id}`,
  });

  return response.data;
}

async function createExhibition(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/exhibitions',
    data,
  });

  return response.data;
}

async function deleteExhibition(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/exhibitions/${id}`,
  });

  return response.data;
}

async function updateExhibition(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/exhibitions/${id}`,
    data,
  });

  return response.data;
}

export default {
  listExhibitions,
  getExhibition,
  createExhibition,
  deleteExhibition,
  updateExhibition,
};
