<template>
  <v-container>

    <v-form
      ref="exhibitionItemForm"
      v-model="valid"
      lazy-validation
    >

      <!-- Name -->
      <v-row>
        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="exhibitionItemFormValue.name.hu"
            :label="$t('exhibitionItems.form.nameHU')"
            :rules="nameRules"
            required
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="exhibitionItemFormValue.name.en"
            :label="$t('exhibitionItems.form.nameEN')"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="4"
        >
          <v-text-field
            v-model="exhibitionItemFormValue.name.de"
            :label="$t('exhibitionItems.form.nameDE')"
          ></v-text-field>
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('exhibitionItems.form.descriptionHU')}}</h3>

          <editor ref="toastuiEditorHu"
            v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
            :initialValue="exhibitionItemFormValue.description.hu"
            :initialEditType="'markdown'"
            :height="'800px'"
            :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>
          <h3>{{$t('exhibitionItems.form.descriptionEN')}}</h3>

          <editor ref="toastuiEditorEn"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="exhibitionItemFormValue.description.en"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- Description -->
      <v-row>
        <v-col>

          <h3>{{$t('exhibitionItems.form.descriptionDE')}}</h3>

          <editor ref="toastuiEditorDe"
                  v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
                  :initialValue="exhibitionItemFormValue.description.de"
                  :initialEditType="'markdown'"
                  :height="'800px'"
                  :options="editorOptions"
          />
        </v-col>
      </v-row>

      <!-- isHidden -->
      <v-row>
        <v-col>
          <v-checkbox
            v-model="exhibitionItemFormValue.isHidden"
            :label="$t('exhibitionItems.form.isHidden')"
          ></v-checkbox>
        </v-col>
      </v-row>

      <!-- Media carousel -->
      <v-row v-if="exhibitionItem">
        <v-col>

          <v-carousel v-model="carousel" height="auto">
            <v-carousel-item
              v-for="media in exhibitionItem.media" :key="media.id"
            >
              <Media :media="media"></Media>
            </v-carousel-item>
          </v-carousel>

        </v-col>
      </v-row>

      <!-- Media input -->
      <v-row>
        <v-col>

          <v-file-input
            chips
            counter
            multiple
            show-size
            accept=".png, .jpg, .jpeg, .mp3, .mp4, .obj, .stl"
            v-model="newMedia"
            :label="$t('exhibitionItems.form.media')"
            :rules="isUpdate ? mediaUpdateRules : mediaRules"
          ></v-file-input>

        </v-col>
      </v-row>

      <Alert
        v-if="error"
        dismissable
        elevation="5"
        type="error"
        :message="error"
        :onClose="resetError"
      />

      <v-btn
        :disabled="!valid || loading"
        color="primary"
        type="submit"
        class="mr-4 mt-2"
        @click="submit"
      >{{isUpdate ? $t('common.update') : $t('common.create')}}</v-btn>

    </v-form>

  </v-container>
</template>

<script>
import { Editor } from '@toast-ui/vue-editor';

import i18n from '@/i18n';

import Alert from '@/components/Alert';
import Media from '@/components/Media';

export default {
  name: 'ExhibitionItemForm',
  components: {
    Alert,
    Media,
    Editor,
  },
  async created() {
    this.$store.dispatch('room/listAllRooms', {});
    this.$store.dispatch('exhibitionItem/clearError');
    if (this.$router.history.current.fullPath.includes('update')) {
      await this.$store.dispatch('exhibitionItem/getExhibitionItem', {
        id: this.$route.params.exhibitionItemId,
        exhibitionId: this.$route.params.id,
      });
      this.isUpdate = true;
      this.exhibitionItemFormValue = this.exhibitionItem;
      this.$refs.toastuiEditorHu.invoke('setMarkdown', this.exhibitionItemFormValue.description.hu, true);
      this.$refs.toastuiEditorEn.invoke('setMarkdown', this.exhibitionItemFormValue.description.en, true);
      this.$refs.toastuiEditorDe.invoke('setMarkdown', this.exhibitionItemFormValue.description.de, true);
    }
  },
  data() {
    return {
      isUpdate: false,
      valid: true,
      carousel: null,
      newMedia: [],
      exhibitionItemFormValue: {
        name: {
          hu: '',
          en: '',
          de: '',
        },
        description: {
          hu: '',
          en: '',
          de: '',
        },
        roomId: null,
        media: [],
        isHidden: false,
      },
      nameRules: [
        (v) => !!v || i18n.t('exhibitionItems.form.nameRule'),
      ],
      descriptionRules: [
        (v) => !!v || i18n.t('exhibitionItems.form.descriptionRule'),
      ],
      mediaRules: [
        (v) => (v.filter((file) => (file.size >= 100 * 1024 * 1024)).length > 0
          ? i18n.t('exhibitionItems.form.mediaSizeRule')
          : true),
      ],
      mediaUpdateRules: [
        (v) => {
          if (this.isUpdate) return true;
          return v.filter((file) => (file.size >= 100 * 1024 * 1024)).length > 0
            ? i18n.t('exhibitionItems.form.mediaSizeRule')
            : true;
        },
      ],
      exhibitionId: this.$route.params.id,
      editorOptions: {
        usageStatistics: false,
        hideModeSwitch: true,
      },
    };
  },
  computed: {
    loading() {
      return this.$store.state.exhibitionItem.loading;
    },
    exhibitionItem() {
      return this.$store.state.exhibitionItem.selectedExhibitionItem;
    },
    error() {
      return this.$store.state.exhibitionItem.error;
    },
    rooms() {
      return this.$store.state.room.rooms;
    },
    loadingRooms() {
      return this.$store.state.room.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async submit(event) {
      event.preventDefault();
      if (this.$refs.exhibitionItemForm.validate()) {
        const {
          name, roomId, isHidden,
        } = this.exhibitionItemFormValue;

        if (this.isUpdate) {
          const data = {
            name,
            description: {
              hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
              en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
              de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
            },
            roomId,
            media: this.newMedia,
            isHidden,
          };
          // TODO: Error handling
          await this.$store.dispatch('exhibitionItem/updateExhibitionItem', {
            data,
            exhibitionId: this.exhibitionId,
            id: this.$route.params.exhibitionItemId,
          });
          this.$router.push(
            `/exhibitions/${this.exhibitionId}/exhibition-items/${this.$route.params.exhibitionItemId}`,
          );
        } else {
          // TODO: Error handling
          const createdExhibitionItem = await this.$store.dispatch('exhibitionItem/createExhibitionItem', {
            exhibitionId: this.exhibitionId,
            data: {
              name,
              description: {
                hu: this.$refs.toastuiEditorHu.invoke('getMarkdown'),
                en: this.$refs.toastuiEditorEn.invoke('getMarkdown'),
                de: this.$refs.toastuiEditorDe.invoke('getMarkdown'),
              },
              roomId,
              media: this.newMedia,
              isHidden,
            },
          });
          this.$router.push(`/exhibitions/${this.exhibitionId}/exhibition-items/${createdExhibitionItem.id}`);
        }
      }

      return false;
    },
    resetError() {
      this.$store.dispatch('exhibitionItem/clearError');
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
