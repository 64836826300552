<template>
  <v-container>
    <template v-if="room">

      <v-card
        elevation="3"
        outlined
        :loading="loading"
        class="mb-4"
      >

        <v-card-title>
          {{room.name[language]}}
        </v-card-title>

        <v-card-text>
          <viewer
            ref="toastuiViewer"
            v-bind:class="{ 'toastui-editor-dark': isDarkMode }"
            :initialValue="room.description ? room.description[language] : ''"
            :options="{ usageStatistics: false }" />
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            :to="`/floors/${floorId}/rooms/${room.id}/update`"
          >
            {{ $t('common.update') }}
          </v-btn>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            @click="deleteRoom(room)"
          >
            {{ $t('common.delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>

    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import { Viewer } from '@toast-ui/vue-editor';

import i18n from '@/i18n';

export default {
  name: 'ShowRoom',
  components: {
    Viewer,
  },
  created() {
    this.$store.dispatch('room/getRoom', { floorId: this.$route.params.floorId, id: this.$route.params.id });
  },
  computed: {
    room() {
      return this.$store.state.room.selectedRoom;
    },
    loading() {
      return this.$store.state.room.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    floorId() {
      return this.$route.params.floorId;
    },
    isDarkMode() {
      return this.$store.state.theme.dark;
    },
  },
  methods: {
    async deleteRoom(room) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('rooms.deleteConfirm'),
        { color: 'red', itemName: room.name[this.language] },
      )) {
        await this.$store.dispatch('room/deleteRoom', room);
        this.$router.push('/rooms');
      }
    },
  },
  watch: {
    language() {
      this.$refs.toastuiViewer.invoke('setMarkdown', this.room.content[this.language], true);
    },
  },
};
</script>

<style scoped>

</style>
