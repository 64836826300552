/* eslint-disable no-console */
import { tour as tourService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  tours: [],
  totalTours: 0,
  selectedTour: null,
  loading: false,
};

const actions = {
  async listTours({ commit }, { exhibitionId, page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const tours = await tourService.listTours(exhibitionId, page, itemsPerPage);
      commit('listToursSuccess', {
        total: tours.meta.total,
        tours: tours.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getTour({ commit }, { exhibitionId, id }) {
    commit('setLoading', true);
    try {
      const tour = await tourService.getTour(exhibitionId, id);
      commit('getTourSuccess', tour);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createTour({ commit }, {
    data, exhibitionId,
  }) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return tourService
      .createTour(exhibitionId, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteTour({ commit }, { exhibitionId, id }) {
    commit('setLoading', true);
    try {
      await tourService.deleteTour(exhibitionId, id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateTour(
    { commit },
    {
      id, data, exhibitionId,
    },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return tourService
      .updateTour(exhibitionId, id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  updateTourExhibitionItems(
    { commit },
    {
      id, exhibitionItems, exhibitionId,
    },
  ) {
    commit('setLoading', true);
    return tourService
      .updateTourExhibitionItems(exhibitionId, id, exhibitionItems)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listToursSuccess(state, { tours, total }) {
    state.tours = tours;
    state.totalTours = total;
  },
  getTourSuccess(state, tour) {
    state.selectedTour = tour;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  tours(state) {
    return state.tours;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
