/* eslint-disable no-console */
import { device as deviceService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  devices: [],
  totalDevices: 0,
  selectedDevice: null,
  loading: false,
};

const actions = {
  async listDevices({ commit }, { page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const devices = await deviceService.listDevices(page, itemsPerPage);
      commit('listDevicesSuccess', {
        total: devices.meta.total,
        devices: devices.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getDevice({ commit }, { id }) {
    commit('setLoading', true);
    try {
      const device = await deviceService.getDevice(id);
      commit('getDeviceSuccess', device);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createDevice({ commit }, data) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return deviceService
      .createDevice(formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteDevice({ commit }, { id }) {
    commit('setLoading', true);
    try {
      await deviceService.deleteDevice(id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateDevice(
    { commit },
    { id, data },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return deviceService
      .updateDevice(id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  listDevicesSuccess(state, { total, devices }) {
    state.devices = devices;
    state.totalDevices = total;
  },
  getDeviceSuccess(state, device) {
    state.selectedDevice = device;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const getters = {
  devices(state) {
    return state.devices;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
