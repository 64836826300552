<template>
  <v-container>
    <template v-if="tour">

      <h1 class="mb-4">{{tour.name[language]}}</h1>

      <v-row>

        <v-col cols="6">
          <exhibition-item-list :exhibition-id="exhibitionId" show-draggable-column></exhibition-item-list>
        </v-col>

        <v-col cols="6" @drop='onDrop($event)' @dragover.prevent @dragenter.prevent>
          <v-data-table
            :headers="headers"
            :items="tour.exhibitionItems"
            :loading="loading"
            :options="{ itemsPerPage: 100 }"
            hide-default-footer
          >

            <template v-slot:item.open="{ item }">
              <v-btn icon small :to="`/exhibitions/${exhibitionId}/exhibition-items/${item.id}`" >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>

            <template v-slot:item.actions="{ item, index }">
              <v-btn
                icon
                v-if="index > 0"
                @click="moveUp(item)"
                :disabled="loading"
              >
                <v-icon>
                  mdi-chevron-up
                </v-icon>
              </v-btn>

              <div v-else class="empty-button"></div>

              <v-btn
                icon
                v-if="index < tour.exhibitionItems.length - 1"
                @click="moveDown(item)"
                :disabled="loading"
              >
                <v-icon>
                  mdi-chevron-down
                </v-icon>
              </v-btn>

              <div v-else class="empty-button"></div>

              <v-btn
                icon
                @click="removeItem(item)"
                :disabled="loading"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-col>

      </v-row>

      <div class="actions">
        <v-btn
          color="primary"
          :to="`/exhibitions/${exhibitionId}/tours/${tour.id}/update`"
        >
          {{ $t('common.update') }}
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          color="error"
          @click="deleteTour(tour)"
        >
          {{ $t('common.delete') }}
        </v-btn>

      </div>
    </template>
    <template v-else>
      {{ $t('common.loading') }}
    </template>
  </v-container>
</template>

<script>
import i18n from '@/i18n';
import ExhibitionItemList from '../../components/ExhibitionItemList';

export default {
  name: 'ShowTour',
  components: { ExhibitionItemList },
  created() {
    this.loadTour();
  },
  data() {
    return {
      exhibitionId: this.$route.params.id,
    };
  },
  computed: {
    tour() {
      return this.$store.state.tour.selectedTour;
    },
    loading() {
      return this.$store.state.tour.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('exhibitionItems.list.open'),
          value: 'open',
          sortable: false,
        },
        {
          text: i18n.t('exhibitionItems.list.name'),
          value: `name.${this.language}`,
          sortable: false,
        },
        { text: i18n.t('tours.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    loadTour() {
      this.$store.dispatch('tour/getTour', {
        id: this.$route.params.tourId,
        exhibitionId: this.exhibitionId,
      });
    },
    async deleteTour(tour) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('tours.deleteConfirm'),
        { color: 'red', itemName: tour.name[this.language] },
      )) {
        await this.$store.dispatch('tour/deleteTour', {
          id: tour.id,
          exhibitionId: this.$route.params.id,
        });
        this.$router.push(`/exhibitions/${this.exhibitionId}/tours`);
      }
    },

    async onDrop(event) {
      const droppedExhibitionItemId = event.dataTransfer.getData('id');
      const existingExhibitionItem = this.tour.exhibitionItems.find(({ id }) => id === droppedExhibitionItemId);
      if (!existingExhibitionItem) {
        await this.$store.dispatch('tour/updateTourExhibitionItems', {
          exhibitionId: this.exhibitionId,
          id: this.tour.id,
          exhibitionItems: [
            ...this.tour.exhibitionItems.map(({ id: exhibitionItemId, order }) => ({ exhibitionItemId, order })),
            {
              exhibitionItemId: droppedExhibitionItemId,
              order: this.tour.exhibitionItems.length > 0
                ? this.tour.exhibitionItems[this.tour.exhibitionItems.length - 1].order + 1
                : 1,
            },
          ],
        });

        this.loadTour();
      }
    },

    async moveUp(exhibitionItem) {
      const exhibitionItemIndex = this.tour.exhibitionItems.findIndex(({ id }) => id === exhibitionItem.id);
      if (exhibitionItemIndex === -1) {
        return;
      }

      const exhibitionItems = [...this.tour.exhibitionItems];
      const [prevItem, currentItem] = exhibitionItems.slice(exhibitionItemIndex - 1, exhibitionItemIndex + 1);

      exhibitionItems.splice(exhibitionItemIndex - 1, 2,
        { ...currentItem, order: prevItem.order },
        { ...prevItem, order: currentItem.order });

      await this.$store.dispatch('tour/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.tour.id,
        exhibitionItems: exhibitionItems
          .map(({ id: exhibitionItemId, order }) => ({ exhibitionItemId, order })),
      });

      this.loadTour();
    },

    async moveDown(exhibitionItem) {
      const exhibitionItemIndex = this.tour.exhibitionItems.findIndex(({ id }) => id === exhibitionItem.id);
      if (exhibitionItemIndex === -1) {
        return;
      }

      const exhibitionItems = [...this.tour.exhibitionItems];
      const [currentItem, nextItem] = exhibitionItems.slice(exhibitionItemIndex, exhibitionItemIndex + 2);

      exhibitionItems.splice(exhibitionItemIndex, 2,
        { ...nextItem, order: currentItem.order },
        { ...currentItem, order: nextItem.order });

      await this.$store.dispatch('tour/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.tour.id,
        exhibitionItems: exhibitionItems
          .map(({ id: exhibitionItemId, order }) => ({ exhibitionItemId, order })),
      });

      this.loadTour();
    },

    async removeItem(exhibitionItem) {
      await this.$store.dispatch('tour/updateTourExhibitionItems', {
        exhibitionId: this.exhibitionId,
        id: this.tour.id,
        exhibitionItems: this.tour.exhibitionItems
          .filter(({ id }) => exhibitionItem.id !== id)
          .map(({ id: exhibitionItemId, order }) => ({ exhibitionItemId, order })),
      });

      this.loadTour();
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-button {
  width: 36px;
  height: 36px;
  display: inline-flex;
  vertical-align: middle;
}
.actions {
  align-items: center;
  display: flex;
  padding: 16px 0;

  .v-btn + .v-btn {
    margin-left: 8px;
  }
}
</style>
