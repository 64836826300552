<template>
  <v-expansion-panels v-if="rooms">
    <v-expansion-panel>

      <v-expansion-panel-header>
        {{ $t('rooms.rooms') }}
      </v-expansion-panel-header>

      <v-expansion-panel-content>

        <v-data-table
          :headers="headers"
          :items="rooms"
          :loading="loading"
          :items-per-page="-1"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-btn color="primary" :to="`/floors/${floorId}/rooms/new`" class="ma-4">
              {{ $t('rooms.list.newRoom') }}
            </v-btn>
          </template>

          <template v-slot:item.open="{ item }">
            <v-btn icon small :to="`/floors/${floorId}/rooms/${item.id}`" >
              <v-icon>
                mdi-eye
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:item.isCorridor="{ item }">
            {{ $t(item.isCorridor ? 'common.yes' : 'common.no') }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              icon
              small
              class="mr-2"
              :to="`/floors/${floorId}/rooms/${item.id}/update`">
              <v-icon
                small
                :to="`/floors/${floorId}/rooms/${item.id}/update`"
              >
                mdi-pencil
              </v-icon>
            </v-btn>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.createdAt="{ item }">
            <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
          </template>

          <template v-slot:item.updatedAt="{ item }">
            <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
          </template>
        </v-data-table>

      </v-expansion-panel-content>

    </v-expansion-panel>
  </v-expansion-panels>

  <v-row v-else justify="center" align="center" class="max-height">
    {{ $t('rooms.list.noRoom') }}
    <v-btn plain color="primary" :to="`/rooms/new`">
      {{ $t('rooms.list.newRoom') }}
    </v-btn>
  </v-row>

</template>

<script>
/* eslint-disable no-console, no-param-reassign */
import i18n from '@/i18n';

export default {
  name: 'RoomList',
  props: {
    floorId: String,
    rooms: Array,
    onDeleted: Function,
  },
  methods: {
    async deleteItem({ id, name }) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('rooms.deleteConfirm'),
        { color: 'red', itemName: name[this.language] },
      )) {
        await this.$store.dispatch('room/deleteRoom', { floorId: this.floorId, id });
        if (this.onDeleted) {
          this.onDeleted(id);
        }
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.room.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('rooms.list.open'),
          value: 'open',
          sortable: false,
          width: '64px',
        },
        {
          text: i18n.t('rooms.list.name'),
          value: `name.${this.language}`,
          sortable: false,
        },
        {
          text: i18n.t('rooms.list.isCorridor'),
          value: 'isCorridor',
          sortable: false,
        },
        {
          text: i18n.t('rooms.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('rooms.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('rooms.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
};
</script>

<style scoped>

</style>
