import router from '@/router';

import { auth } from '@/services';

const parsedUser = JSON.parse(localStorage.getItem('user'));
const parsedToken = JSON.parse(localStorage.getItem('token'));

const initialState = parsedUser
  ? { status: { loggedIn: true, error: null }, user: parsedUser, token: parsedToken }
  : { status: { loggedIn: false, error: null }, user: null, token: null };

const actions = {
  login({ commit }, { email, password }) {
    commit('loginRequest', { email });

    auth.login({ email, password })
      .then(
        ({ user, token }) => {
          commit('loginSuccess', user, token);
          router.push('/exhibitions'); // TODO redirect to `redirectTo` parameter
        },
        (error) => {
          // eslint-disable-next-line
          console.error(error);
          commit('loginFailure', error);
        },
      );
  },
  async logout({ commit }) {
    try {
      await auth.logout();
    } catch (e) {
      // We can ignore this exception amd
      // eslint-disable-next-line
      console.warn('There was an error during logout');
    }
    await router.push('/login');
    commit('logout');
  },
  clearError({ commit }) {
    commit('clearError');
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = {
      ...state.status, loggingIn: true, loggedIn: false, error: null,
    };
    state.user = user;
  },
  loginSuccess(state, user, token) {
    state.status = {
      ...state.status, loggingIn: false, loggedIn: true, error: null,
    };
    state.user = user;
    state.token = token;
  },
  loginFailure(state, error) {
    state.status = {
      ...state.status, error, loggingIn: false, loggedIn: false,
    };
    state.user = null;
    state.token = null;
  },
  logout(state) {
    state.status = {
      ...state.status, loggingIn: false, loggedIn: false, error: null,
    };
    state.user = null;
    state.token = null;
  },
  clearError(state) {
    state.status.error = null;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
};
