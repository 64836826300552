<template>
  <v-container>

    <template v-if="staticSites.length">

      <v-data-table
        :headers="headers"
        :items="staticSites"
        :options.sync="options"
        :items-per-page="15"
        :server-items-length="totalStaticSites"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-btn color="primary" :to="`/static-sites/new`" class="ma-4">
            {{ $t('staticSites.list.newStaticSite') }}
          </v-btn>
        </template>

        <template v-slot:item.open="{ item }">
          <v-btn icon small :to="`/static-sites/${item.id}`" >
            <v-icon>
              mdi-eye
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn
            icon
            small
            text
            class="mr-2"
            :to="`/static-sites/${item.id}/update`">
            <v-icon
              small
            >
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            icon
            small
            text
            class="ma-2"
            color="error"
            @click="deleteStaticSite(item)">
            <v-icon
              small
            >
              mdi-delete
            </v-icon>
          </v-btn>
        </template>

        <template v-slot:item.createdAt="{ item }">
          <span>{{ new Date(item.createdAt).toLocaleString() }}</span>
        </template>

        <template v-slot:item.updatedAt="{ item }">
          <span>{{ new Date(item.updatedAt).toLocaleString() }}</span>
        </template>
      </v-data-table>

    </template>

    <template v-else>
      <v-row justify="center" align="center" class="max-height">
        {{ $t('staticSites.list.noStaticSite') }}
        <v-btn plain color="primary" to="/static-sites/new">
          {{ $t('staticSites.list.newStaticSite') }}
        </v-btn>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import i18n from '@/i18n';

export default {
  name: 'StaticSiteListPage',
  components: {},
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  data: () => ({
    dialog: false,
    options: {},
  }),
  created() {
    this.$store.dispatch('staticSite/listStaticSites', {});
  },
  computed: {
    staticSites() {
      return this.$store.state.staticSite.staticSites;
    },
    totalStaticSites() {
      return this.$store.state.staticSite.totalStaticSites;
    },
    loading() {
      return this.$store.state.staticSite.loading;
    },
    language() {
      return this.$i18n.locale;
    },
    headers() {
      return [
        {
          text: i18n.t('staticSites.list.open'),
          value: 'open',
          sortable: false,
        },
        {
          text: i18n.t('staticSites.list.name'),
          value: 'name',
          sortable: false,
        },
        {
          text: i18n.t('staticSites.list.slug'),
          value: 'slug',
          sortable: false,
        },
        {
          text: i18n.t('staticSites.list.createdAt'),
          value: 'createdAt',
          sortable: false,
        },
        {
          text: i18n.t('staticSites.list.updatedAt'),
          value: 'updatedAt',
          sortable: false,
        },
        { text: i18n.t('staticSites.list.actions'), value: 'actions', sortable: false },
      ];
    },
  },
  methods: {
    getDataFromApi() {
      const {
        page, itemsPerPage,
      } = this.options;
      this.$store.dispatch('staticSite/listStaticSites', {
        page,
        itemsPerPage,
      });
    },

    async deleteStaticSite({ id, name }) {
      if (await this.$root.$confirm.open(
        i18n.t('common.delete'),
        i18n.t('staticSites.deleteConfirm'),
        { color: 'red', itemName: name },
      )) {
        await this.$store.dispatch('staticSite/deleteStaticSite', { id });

        // If the removed item was the last one in the page we need to go to the previous page
        if (this.staticSites.length - 1 <= 0 && this.options.page > 1) {
          this.options.page -= 1;
        }

        this.getDataFromApi();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  .max-height {
    height: 100%;
  }
}
</style>
