import axios from './axios';

async function listQuizzes(page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: '/admin/quizzes',
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function getQuiz(id) {
  const response = await axios({
    method: 'get',
    url: `/admin/quizzes/${id}`,
  });

  return response.data;
}

async function createQuiz(data) {
  const response = await axios({
    method: 'post',
    url: '/admin/quizzes',
    data,
  });

  return response.data;
}

async function deleteQuiz(id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/quizzes/${id}`,
  });

  return response.data;
}

async function updateQuiz(id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/quizzes/${id}`,
    data,
  });

  return response.data;
}

export default {
  getQuiz,
  createQuiz,
  deleteQuiz,
  updateQuiz,
  listQuizzes,
};
