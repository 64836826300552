/* eslint-disable no-console */
import { room as roomService } from '@/services';
import { jsonToFormData } from '@/utils';

const initialState = {
  error: '',
  rooms: [],
  totalRooms: 0,
  selectedRoom: null,
  loading: false,
};

const actions = {
  async listRooms({ commit }, { floorId, page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const rooms = await roomService.listRooms(floorId, page, itemsPerPage);
      commit('listRoomsSuccess', {
        total: rooms.meta.total,
        rooms: rooms.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async listAllRooms({ commit }, { page, itemsPerPage }) {
    commit('setLoading', true);
    try {
      const rooms = await roomService.listAllRooms(page, itemsPerPage);
      commit('listRoomsSuccess', {
        total: rooms.meta.total,
        rooms: rooms.data,
      });
    } catch (error) {
      console.error(error);
    } finally {
      commit('setLoading', false);
    }
  },
  async getRoom({ commit }, { floorId, id }) {
    commit('setLoading', true);
    try {
      const room = await roomService.getRoom(floorId, id);
      commit('getRoomSuccess', room);
    } catch (e) {
      console.error(e);
    } finally {
      commit('setLoading', false);
    }
  },
  createRoom({ commit }, {
    data, floorId,
  }) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return roomService
      .createRoom(floorId, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  async deleteRoom({ commit }, { floorId, id }) {
    commit('setLoading', true);
    try {
      await roomService.deleteRoom(floorId, id);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  updateRoom(
    { commit },
    {
      id, data, floorId,
    },
  ) {
    commit('setLoading', true);
    const formData = jsonToFormData(data);
    return roomService
      .updateRoom(floorId, id, formData)
      .catch((e) => {
        commit('setError', e);
      }).finally(() => {
        commit('setLoading', false);
      });
  },
  clearError({ commit }) {
    commit('clearError');
  },
  clearSelected({ commit }) {
    commit('clearSelected');
  },
};

const mutations = {
  listRoomsSuccess(state, { rooms, total }) {
    state.rooms = rooms;
    state.totalRooms = total;
  },
  getRoomSuccess(state, room) {
    state.selectedRoom = room;
  },
  clearError(state) {
    state.error = null;
  },
  setError(state, e) {
    state.error = e;
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
  clearSelected(state) {
    state.selectedRoom = null;
  },
};

const getters = {
  rooms(state) {
    return state.rooms;
  },
};

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters,
};
