import axios from './axios';

async function listRooms(floorId, page = 1, itemsPerPage = 20) {
  const response = await axios({
    method: 'get',
    url: `/admin/floors/${floorId}/rooms`,
    params: {
      page, itemsPerPage,
    },
  });

  return response.data;
}

async function listAllRooms(page = 1, limit = 20) {
  const response = await axios({
    method: 'get',
    url: '/rooms',
    params: {
      page, limit,
    },
  });

  return response.data;
}

async function getRoom(floorId, id) {
  const response = await axios({
    method: 'get',
    url: `/admin/floors/${floorId}/rooms/${id}`,
  });

  return response.data;
}

async function createRoom(floorId, data) {
  const response = await axios({
    method: 'post',
    url: `/admin/floors/${floorId}/rooms`,
    data,
  });

  return response.data;
}

async function deleteRoom(floorId, id) {
  const response = await axios({
    method: 'delete',
    url: `/admin/floors/${floorId}/rooms/${id}`,
  });

  return response.data;
}

async function updateRoom(floorId, id, data) {
  const response = await axios({
    method: 'put',
    url: `/admin/floors/${floorId}/rooms/${id}`,
    data,
  });

  return response.data;
}

export default {
  listRooms,
  getRoom,
  createRoom,
  deleteRoom,
  updateRoom,
  listAllRooms,
};
